@media print {
  .main-header {
    position: relative !important;
    .header-middle {
      position: relative !important;
    }
  }
  .animate {
    visibility: visible !important;
  }
  .tx-cs2-events {
    .events-mobile {
      .events-mobile-list {
        > .col-12 {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }
  .content-wrapper {
    width: 100%;
    max-width: 100%;
  }
  #TSFE_ADMIN_PANEL_FORM {
    display: none;
  }
  .tx-cs2-teaserElement {
    .offset {
      .offset-layer {
        .bg-offset {
          img {
            top: 0 !important;
            left: 0 !important;
            max-width: fit-content !important;
            max-height: fit-content !important;
          }
        }
      }
    }
  }
}
