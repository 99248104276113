.tab {
  .content-wrapper {
    padding: 0;
  }
  .nav-pills {
    .nav-item + .nav-item {
      margin-left: .5rem;
    }
  }
  .nav-link {
    @extend .font-size-ui;
  }
}
