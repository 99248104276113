$selectize: "selectize" !default;
$select-font-family: inherit !default;
$select-font-smoothing: inherit !default;
$select-font-size: inherit !default;
$select-line-height: inherit !default;

$select-color-text: $black !default;
$select-color-border: $black !default;
$select-color-highlight: rgba($light, 0.2) !default;
$select-color-input: $white !default;
$select-color-input-full: $select-color-input !default;
$select-color-disabled: #fafafa !default;
$select-color-item: $gray-300 !default;
$select-color-item-text: $select-color-text !default;
$select-color-item-border: #d0d0d0 !default;
$select-color-item-active: #e8e8e8 !default;
$select-color-item-active-text: $select-color-text !default;
$select-color-item-active-border: #cacaca !default;
$select-color-dropdown: #fff !default;
$select-color-dropdown-border: $select-color-border !default;
$select-color-dropdown-border-top: #f0f0f0 !default;
$select-color-dropdown-item-active: #f5fafd !default;
$select-color-dropdown-item-active-text: #495c68 !default;
$select-color-dropdown-item-create-text: rgba(
    red($select-color-text),
    green($select-color-text),
    blue($select-color-text),
    0.5
) !default;
$select-color-dropdown-item-create-active-text: $select-color-dropdown-item-active-text !default;
$select-color-optgroup: $select-color-dropdown !default;
$select-color-optgroup-text: $select-color-text !default;
$select-lighten-disabled-item: 30% !default;
$select-lighten-disabled-item-text: 30% !default;
$select-lighten-disabled-item-border: 30% !default;
$select-opacity-disabled: 0.5 !default;

$select-shadow-input: none !default;
$select-shadow-input-focus: none !default;
$select-border: 1px solid $select-color-border !default;
$select-dropdown-border: 1px solid $select-color-dropdown-border !default;
$select-border-radius: 0 !default;

$select-width-item-border: 0px !default;
$select-max-height-dropdown: 200px !default;

$select-padding-x: 8px !default;
$select-padding-y: 8px !default;
$select-padding-item-x: 6px !default;
$select-padding-item-y: 2px !default;
$select-padding-dropdown-item-x: $select-padding-x !default;
$select-padding-dropdown-item-y: 5px !default;
$select-margin-item-x: 3px !default;
$select-margin-item-y: 3px !default;

$select-arrow-size: 5px !default;
$select-arrow-color: #808080 !default;
$select-arrow-offset: 15px !default;

$select-caret-margin: 0 0px !default;
$select-caret-margin-rtl: 0 4px 0 -2px !default;

$select-spinner-size: 30px;
$select-spinner-border-size: 5px;
$select-spinner-border-color: $select-color-border;

.#{$selectize}-control {
  position: relative;
}

.#{$selectize}-dropdown,
.#{$selectize}-input,
.#{$selectize}-input input {
  color: $select-color-text;
  font-family: $select-font-family;
  font-size: $select-font-size;
  line-height: $select-line-height;
  font-smoothing: $select-font-smoothing;
}

.#{$selectize}-input,
.#{$selectize}-control.single .#{$selectize}-input.input-active {
  background: $select-color-input;
  cursor: text;
  display: inline-block;
}

.#{$selectize}-input {
  border: $select-border;
  padding: $select-padding-y $select-padding-x;
  display: inline-block;
  width: 100%;
  // overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: $select-shadow-input;
  border-radius: $select-border-radius;

  .#{$selectize}-control.multi &.has-items {
    $padding-x: $select-padding-x;
    $padding-top: calc(
      #{$select-padding-y} - #{$select-padding-item-y} - #{$select-width-item-border}
    );
    $padding-bottom: calc(
      #{$select-padding-y} - #{$select-padding-item-y} - #{$select-margin-item-y} -
      #{$select-width-item-border}
    );
    padding: .7175rem 2.5rem .7175rem .9375rem;
  }

  &.full {
    background-color: $select-color-input-full;
  }
  &.disabled,
  &.disabled * {
    cursor: default !important;
  }
  &.focus {
    box-shadow: $select-shadow-input-focus;
  }
  &.dropdown-active {
    border-radius: $select-border-radius $select-border-radius 0 0;
  }

  > * {
    vertical-align: baseline;
    display: inline-block;
    zoom: 1;
  }
  .#{$selectize}-control.multi & > div {
    cursor: pointer;
    margin: $select-margin-item-x $select-margin-item-y 0 0;
    padding: $select-padding-item-y $select-padding-item-x;
    background: $select-color-item;
    color: $select-color-item-text;
    border: $select-width-item-border solid $select-color-item-border;


  }
  .#{$selectize}-control.multi &.disabled > div {
    &,
    &.active {
      color: lighten(
          desaturate($select-color-item-text, 100%),
          $select-lighten-disabled-item-text
      );
      background: lighten(
          desaturate($select-color-item, 100%),
          $select-lighten-disabled-item
      );
      border: $select-width-item-border solid
      lighten(
          desaturate($select-color-item-border, 100%),
          $select-lighten-disabled-item-border
      );
    }
  }
  > input {
    &::-ms-clear {
      display: none;
    }
    display: inline-block !important;
    padding: 0 !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    margin: 0 !important;
    text-indent: 0 !important;
    border: 0 none !important;
    background: none !important;
    line-height: inherit !important;
    user-select: auto !important;
    box-shadow: none !important;
    &:focus {
      outline: none !important;
    }

    &[placeholder] {
      box-sizing: initial;
    }
  }
  &.has-items > input {
    margin: $select-caret-margin !important;
  }
}

.#{$selectize}-input::after {
  @extend .icon-5;
  font-family: 'Material Icons';
  vertical-align: 0;
  content: "expand_more";
  border: none;
  color: $black;
  margin-right: -.5rem;
  text-transform: none;
  font-weight: 400;
  position: absolute;
  right: .9375rem;
  top: 50%;
  transform: translateY(-50%);
}
.#{$selectize}-input.dropdown-active {
  &:after {
    transform: translateY(-50%) rotate(-180deg);
  }
}

.#{$selectize}-input.dropdown-active::before {
  content: " ";
  display: block;
  position: absolute;
  background: $select-color-dropdown-border-top;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.#{$selectize}-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;

  border: $select-dropdown-border;
  background: $select-color-dropdown;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 $select-border-radius $select-border-radius;

  [data-selectable] {
    cursor: pointer;
    overflow: hidden;
    .highlight {
      background: $select-color-highlight;
      border-radius: 1px;
    }
  }
  .option,
  .optgroup-header,
  .no-results,
  .create {
    padding: 0.5rem 1rem;
  }
  .option,
  [data-disabled],
  [data-disabled] [data-selectable].option {
    cursor: inherit;
    opacity: 0.5;
  }
  [data-selectable].option {
    opacity: 1;
    cursor: pointer;
  }
  .optgroup:first-child .optgroup-header {
    border-top: 0 none;
  }
  .optgroup-header {
    color: $select-color-optgroup-text;
    background: $select-color-optgroup;
    cursor: default;
  }
  .active {
    background-color: $primary;
    color: $white;
    &.create {
      color: $select-color-dropdown-item-create-active-text;
    }
  }
  .create {
    color: $select-color-dropdown-item-create-text;
  }
}

.#{$selectize}-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: $select-max-height-dropdown;
  overflow-scrolling: touch;
}

.#{$selectize}-dropdown-emptyoptionlabel {
  text-align: center;
}

.#{$selectize}-dropdown .spinner {
  display: inline-block;
  width: $select-spinner-size;
  height: $select-spinner-size;
  margin: $select-padding-dropdown-item-y $select-padding-dropdown-item-x;

  &:after {
    content: " ";
    display: block;
    width: $select-spinner-size * 0.8;
    height: $select-spinner-size * 0.8;
    margin: $select-spinner-size * 0.1;
    border-radius: 50%;
    border: $select-spinner-border-size solid $select-spinner-border-color;
    border-color: $select-spinner-border-color transparent
    $select-spinner-border-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.#{$selectize}-control.single .#{$selectize}-input {
  &,
  input {
    cursor: pointer;
  }
  &.input-active,
  &.input-active input {
    cursor: text;
  }

  &:after {

  }
  &.dropdown-active:after {

  }
}

.#{$selectize}-control.rtl {
  text-align: right;
  &.single .#{$selectize}-input:after {
    left: $select-arrow-offset;
    right: auto;
  }
  .#{$selectize}-input > input {
    margin: $select-caret-margin-rtl !important;
  }
}

.#{$selectize}-control .#{$selectize}-input.disabled {
  opacity: $select-opacity-disabled;
  background-color: $select-color-disabled;
}

$enable-shadows: false !default;
$select-font-family: inherit !default;
$select-font-size: inherit !default;
$select-line-height: $input-btn-line-height !default; //formerly line-height-computed

$select-color-text: gray("800") !default; //$gray-800
$select-color-highlight: $primary !default;
$select-color-input: $input-bg !default;
$select-color-input-full: $input-bg !default;
$select-color-input-error: theme-color("danger") !default;
$select-color-input-error-focus: darken(
    $select-color-input-error,
    10%
) !default;
$select-color-disabled: $input-bg !default;
$select-color-item: #efefef !default;
$select-color-item-border: $border-color !default;
$select-color-item-active: $component-active-bg !default;
$select-color-item-active-text: #fff !default;
$select-color-item-active-border: rgba(0, 0, 0, 0) !default;
$select-color-optgroup: $dropdown-bg !default;
$select-color-optgroup-text: $dropdown-header-color !default;
$select-color-optgroup-border: $dropdown-divider-bg !default;
$select-color-dropdown: $dropdown-bg !default;
$select-color-dropdown-border-top: mix(
    $input-border-color,
    $input-bg,
    0.8
) !default;
$select-color-dropdown-item-active: $dropdown-link-hover-bg !default;
$select-color-dropdown-item-active-text: $dropdown-link-hover-color !default;
$select-color-dropdown-item-create-active-text: $dropdown-link-hover-color !default;
$select-opacity-disabled: 0.5 !default;
$select-shadow-input: none !default;
$select-shadow-input-focus: inset 0 1px 2px rgba(0, 0, 0, 0.15) !default;
$select-shadow-input-error: inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;
$select-shadow-input-error-focus: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($select-color-input-error, 20%) !default;
$select-border: 1px solid $input-border-color !default;
$select-border-radius: $input-border-radius !default;

$select-width-item-border: 0px !default;
$select-padding-x: $input-btn-padding-x !default;
$select-padding-y: $input-btn-padding-y !default;
$select-padding-dropdown-item-x: $input-btn-padding-x !default;
$select-padding-dropdown-item-y: 3px !default;
$select-padding-item-x: 5px !default;
$select-padding-item-y: 1px !default;
$select-margin-item-x: 3px !default;
$select-margin-item-y: 3px !default;

$select-arrow-size: 5px !default;
$select-arrow-color: $select-color-text !default;
$select-arrow-offset: calc(#{$select-padding-x} + 5px) !default;

.#{$selectize}-dropdown,
.#{$selectize}-dropdown.form-control {
  height: auto;
  padding: 0;
  margin: 2px 0 0 0;
  z-index: $zindex-dropdown;
  background: $select-color-dropdown;
  border: 1px solid $dropdown-border-color; //$dropdown-fallback-border
  border-radius: $border-radius;
}

.#{$selectize}-dropdown {
  .optgroup-header {
    font-size: $font-size-sm;
    line-height: $line-height-base;
  }
  .optgroup:first-child:before {
    display: none;
  }
  .optgroup:before {
    content: " ";
    display: block;
    height: 0;
    margin: $dropdown-divider-margin-y 0;
    overflow: hidden;
    border-top: 1px solid $dropdown-divider-bg;
    margin-left: $select-padding-dropdown-item-x * -1;
    margin-right: $select-padding-dropdown-item-x * -1;
  }

  .create {
    padding-left: $select-padding-dropdown-item-x;
  }
}

.#{$selectize}-dropdown-content {
  padding: 5px 0;
}

.#{$selectize}-dropdown-emptyoptionlabel {
  text-align: center;
}

.#{$selectize}-input {
  min-height: $input-height;
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &.dropdown-active {
    border-radius: $select-border-radius;
  }
  &.dropdown-active::before {
    display: none;
  }
  &.focus {
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.is-invalid .#{$selectize}-input {
  border-color: $select-color-input-error;
  box-shadow: $select-shadow-input-error;

  &:focus {
    border-color: $select-color-input-error-focus;
    box-shadow: $select-shadow-input-error-focus;
  }
}

.#{$selectize}-control {
  &.form-control-sm {
    .#{$selectize}-input.has-items {
      min-height: $input-height-sm !important;
      height: $input-height-sm;
      padding: $input-padding-y-sm $input-padding-x-sm !important;
      font-size: $input-font-size-sm;
      line-height: $input-line-height-sm;
    }
  }

  &.multi {
    .#{$selectize}-input.has-items {
      height: auto;
      padding: .9375rem 2.5rem .9375rem .9375rem;
    }
    .#{$selectize}-input > div {
      border-radius: 0;
      padding-right: 1.25rem;
      position: relative;
      &.active {

      }
      &:after {
        font-size: $font-size-small;
        font-family: 'Material Icons';
        vertical-align: 0;
        content: "close";
        border: none;
        color: $black;
        margin-right: -.5rem;
        text-transform: none;
        font-weight: 400;
        position: absolute;
        right: .65rem;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: 0;
      }
    }
  }
}

.form-control.#{$selectize}-control {
  padding: 0;
  height: auto;
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.input-group .#{$selectize}-input {
  overflow: unset;
  border-radius: 0 $select-border-radius $select-border-radius 0;
}

.selectize-input {
  input[placeholder] {
    padding: .475rem !important;
    width: calc(100% - .95rem) !important;
  }
}
