.tx-cs2-icon {

  .icon-item {
    margin-bottom: 103px;
  }

  .title-box {
    width: 100px;
    .title {
      font-size: 1.25rem;
      font-weight: $font-weight-bolder;
    }
  }

  .icon-box {
    width: 100px;
    position: relative;

    .svg-icon {
      height: 2.6875rem;
      width: auto;
      position: absolute;
      top: -14px;
      right: 10px;
      z-index: 2;
    }

    &:after {
      content: '';
      position: relative;
      display: block;
      height: 2.785rem;
      width: 2.785rem;
      background-color: $secondary;
      margin-right: 0;
      margin-left: auto;
    }
  }
}
