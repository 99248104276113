.font-size-tiny {
  font-size: $font-size-tiny;
}
.font-size-small {
  font-size: $font-size-small;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-tiny;
  }
}
.font-size-ui {
  font-weight: 500;
  font-size: $font-size-regular;
  line-height: 1;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-small;
  }
}
.headings-regular {
  font-weight: 500;
  font-size: $font-size-regular;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-small;
  }
}
.font-size-ui-small {
  font-size: $font-size-small;
  line-height: 1;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-tiny;
  }
}
.font-size-ui-meta {
  font-size: $font-size-small;
  .material-icons {
    font-size: $font-size-regular;
  }
  @include media-breakpoint-down(lg) {
    font-size: $font-size-regular;
  }
}
.font-size-ui-nav {
  font-weight: 500;
  font-size: $font-size-medium;
  line-height: 0.7;
}
.font-size-biggerRegular {
  font-size: $font-size-biggerRegular;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-regular;
  }
}
.font-size-large {
  font-size: $font-size-large;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-medium;
  }
}
blockquote p,
blockquote,
.quote {
  line-height: 1;
  font-weight: 300;
}
blockquote,
blockquote p,
.quote,
.font-size-smallHuge {
  font-size: $font-size-smallHuge;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-smallBig;
  }
}
.font-size-giant {
  font-size: $font-size-giant;
  line-height: 1;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-big;
  }
}
.font-size-stage {
  font-size: $font-size-stage;
  line-height: 1;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-giant;
  }
}
.caption,.description {
  color: $primary;
}

.font-size-regular {
  font-size: $font-size-regular;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-small;
  }
}
/*
.font-size-regular-wrap {
  p, td, th, li, label, .form-control, a:not(.btn) {
    font-size: $font-size-regular;
    @include media-breakpoint-down(sm) {
      font-size: $font-size-small;
    }
  }
}
*/
//.v2 {
//  .font-size-regular {
//    font-size: $font-size-medium;
//    @include media-breakpoint-down(sm) {
//      font-size: $font-size-regular;
//    }
//  }
//
//  .font-size-regular-wrap {
//    #langsDropdown::before,
//    .table thead th,
//    .font-size-ui-meta .material-icons,
//    p, td, th, li, label, .form-control, a:not(.btn):not(.h4-xl-underline) {
//      font-size: $font-size-medium;
//      @include media-breakpoint-down(sm) {
//        font-size: $font-size-regular;
//      }
//    }
//  }
//}

h1,.h1 {
  font-weight: $font-weight-bolder;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size-md;
    line-height: 1.2;
  }
}
h2,.h2 {
  font-weight: $font-weight-normal;
  @include media-breakpoint-down(md) {
    font-size: $h2-font-size-md;
    line-height: 1.2;
  }
}
h3,.h3 {
  font-weight: $font-weight-normal;
  @include media-breakpoint-down(md) {
    font-size: $h3-font-size-md;
    line-height: 1.2;
  }
}
h4,.h4 {
  font-weight: $font-weight-bolder;
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size-md;
    line-height: 1.2;
  }
}
h5,.h5 {
  font-weight: $font-weight-bolder;
  @include media-breakpoint-down(md) {
    font-size: $h5-font-size-md;
    line-height: 1.2;
  }
}
h6,.h6 {
  @include media-breakpoint-down(md) {
    font-size: $h6-font-size-md;
    line-height: 1.2;
  }
}

h1,.h1,
h2,.h2,
h3,.h2,
h4,.h2,
h5,.h2,
h6,.h2 {
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1.25rem;
  }
}

.icon-1 {
  font-size: .5rem !important;
}
.icon-2 {
  font-size: .75rem !important;
}
.icon-3 {
  font-size: 1rem !important;
}
.icon-4 {
  font-size: 1.25rem !important;
}
.icon-5 {
  font-size: 1.75rem !important;
}
.icon-prepp {
  margin-right: .875rem;
}
.icon-app {
  margin-left: .875rem;
}
.underline {
  border-bottom: 1px solid $dark;
  padding-bottom: 1rem;
  @include media-breakpoint-down(xs) {
    border-bottom: none;
  }
}

.icon-link {
  display: inline-block;
  margin: 0;
  &.icon-prepp {
    .material-icons-outlined,
    .material-icons {
      margin-right: .75rem;
    }
  }
  &.icon-app {
    .material-icons-outlined,
    .material-icons {
      margin-left: .75rem;
    }
  }
  .material-icons-outlined,
  .material-icons {
    vertical-align: middle;
    transform: translateY(-4px);
  }
}

.subheader {
  margin-bottom: 1.5rem;
}
.quotespacer {
  width: 48px;
  height: 0;
  border-bottom: 2px solid $black;
  transform: rotate(-14deg);
}

h2, .h2 {
  text-transform: none;
  &.lead {
    text-transform: none;
  }
}

.level-2-title h2, .h2 {
  text-transform: none;
}

  h3, .h3 {
  &.fett {
    font-weight: bolder;
  }
}

.lead {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: $h2-font-size;
    right: calc(100% + 20px);
    width: 8.33333%;
    border-bottom: 2px solid $black;
  }
  @include media-breakpoint-down(sm) {
    font-size: 25px;
    &:before {
      width: 16.66667%;
      top: $h2-font-size-md;
    }
  }

  &.no-line {
    &:before {
      display: none;
    }
  }
}
