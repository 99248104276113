.tx-cs2-links {
  .swiper-controls .swiper-pagination {
    justify-content: flex-end;
    margin-right: -8px;
  }
  .overlay {
    color: $white;
    left:20%;
    top: auto;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: .25rem .5rem;
    clip-path: polygon(0 55%, 100% 0, 100% 100%, 1.5% 100%);
    background: $green;
    height: 33%;
  }
  .links-item {
    min-height: 282px;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      min-height: 164px;
      .overlay {
        clip-path: polygon(0 55%, 100% 0, 100% 100%, 1.5% 100%);
        height: 33%;
      }
     }
   }
  .links-row {
    @include media-breakpoint-down(md) {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
