.tx_solr {
  .results-wrap {
    padding-bottom: 100px;
  }
  .results-list {
    .results-entry {
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid $gray-400;
      .results-topic {
        margin-bottom: 40px;
      }
      .results-teaser {
        color: $body-color;
        .results-highlight {
          background: rgba($yellow,.5);
          font-weight: normal;
          color: $body-color;
        }
      }
      a {
        color: $primary;
      }
    }
  }

  .tx-solr-search-form-top {
    .tx-solr-autosuggest {
      overflow: auto;
    }
  }
  #tx-solr-search {
    #tx-solr-faceting {
      .facets {
        .facet {
          min-width: 210px;
          .badge {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
    .list-group-item {
      .facetType {
        font-weight: bolder;
        font-size: 1.25rem;
        padding: 10px 22px;
        color: $black;
        border: 2px solid $black;
        line-height: 1rem;
        @include media-breakpoint-down(md) {
          padding: 8px 12px;
          font-size: 1.125rem;
        }
      }
    }
  }
}

.tx-solr-autosuggest {
  color: $body-color;
  background: white;
  border-radius: 0;
  /*border: none;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1);*/
  border: 1px solid $gray-400;
  top: 100%;
  /*padding-top: .5rem;*/
  width: calc(100% - 2.5rem) !important;
  text-align: left;
  .autocomplete-group,
  .autocomplete-suggestion {
    @extend .font-size-ui;
    padding: .5rem 1rem;
    strong {
      font-weight: 500;
      text-decoration: underline;
    }
    a {
      color: $body-color;
      &:hover {
        color: $white;
        background: $primary;
        text-decoration: none;
      }
    }

  }
  .autocomplete-group {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .autocomplete-suggestion {
    &:hover {
      background: $primary;
      color: $white;
      text-decoration: none;
      cursor: pointer;
      a {
        color: $white;
      }
    }
  }
}

.big {
  .tx-solr-autosuggest {
    @include media-breakpoint-up(md) {
      width: calc(100% - 4rem) !important;
    }
  }
}

.tx-solr-autocomplete {
  flex: 1 1 auto;
  position: relative;
  .form-control {
    height: 100%;
  }
  &.autocomplete-active {
    .form-control {
      z-index: 10000;
    }
  }
}

.tx_solr {
  .list-group-item {
    &:last-child {
      border: none !important;
    }
  }
}

.facets {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.l-item {
  @extend .font-size-ui;
  a {
    text-decoration: underline;
  }
  + .l-item {
    &:before {
      content: "|";
      display: inline-block;
      margin: 0 .25rem;
      color: $body-color;
      vertical-align: 1px;
    }
  }
}

a .results-topic {
  color: $body-color;
}
a:hover .results-topic {
  color: $primary;
}
