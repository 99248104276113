/*responsive gutter*/
.ml-gap {
  margin-left:  $grid-gutter-width;
}
.mr-gap {
  margin-right:  $grid-gutter-width;
}
.mt-gap {
  margin-top:  $grid-gutter-width;
}
.mb-gap {
  margin-bottom:  $grid-gutter-width;
}
.text-column,
.gap {
  gap: $grid-gutter-width;
}
.px-gap {
  padding-left:  $grid-gutter-width * .5;
  padding-right:  $grid-gutter-width * .5;
}
.px-cssgap {
  padding: 0 var(--gap);
}
.pl-cssgap {
  padding-left: var(--gap);
}
.pr-cssgap {
  padding-right: var(--gap);
}
@include media-breakpoint-down(lg) {
  /*responsive gutter*/
  .px-gap {
    padding-left:  $grid-gutter-width-lg * .5;
    padding-right:  $grid-gutter-width-lg * .5;
  }
  .mx-gap {
    margin-left:  $grid-gutter-width-lg;
    margin-right:  $grid-gutter-width-lg;
  }
  .ml-gap {
    margin-left:  $grid-gutter-width-lg;
  }
  .mr-gap {
    margin-right:  $grid-gutter-width-lg;
  }
  .mt-gap {
    margin-top:  $grid-gutter-width-lg;
  }
  .mb-gap {
    margin-bottom:  $grid-gutter-width-lg;
  }
  .text-column,
  .gap {
    gap: $grid-gutter-width-lg;
  }
  .col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto,.col-xs,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,.col-xs-auto,.col-xxl,.col-xxl-1,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,.col-xxl-9,.col-xxl-auto,
  .container,
  .container-fluid {
    padding-right: $grid-gutter-width-lg * .5;
    padding-left: $grid-gutter-width-lg * .5;
  }
  .row {
    margin-right: -$grid-gutter-width-lg * .5;
    margin-left: -$grid-gutter-width-lg * .5;
  }
}
@include media-breakpoint-down(xs) {
  .px-gap {
    padding-left:  $grid-gutter-width-xs * .5;
    padding-right:  $grid-gutter-width-xs * .5;
  }
  .mx-gap {
    margin-left:  $grid-gutter-width-xs;
    margin-right:  $grid-gutter-width-xs;
  }
  .ml-gap {
    margin-left:  $grid-gutter-width-xs;
  }
  .mr-gap {
    margin-right:  $grid-gutter-width-xs;
  }
  .mt-gap {
    margin-top:  $grid-gutter-width-xs;
  }
  .mb-gap {
    margin-bottom:  $grid-gutter-width-xs;
  }
  .text-column,
  .gap {
    gap: $grid-gutter-width-xs;
  }
  .col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto,.col-xs,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,.col-xs-auto,.col-xxl,.col-xxl-1,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,.col-xxl-9,.col-xxl-auto,
  .container,
  .container-fluid {
    padding-right: $grid-gutter-width-xs * .5;
    padding-left: $grid-gutter-width-xs * .5;
  }
  .row {
    margin-right: -$grid-gutter-width-xs * .5;
    margin-left: -$grid-gutter-width-xs * .5;
  }
}
