.tx-cs2-redTeaserElement {
  .red-teaser-content {
    padding: 2rem 4rem;

    @include media-breakpoint-down(sm) {
      padding: .7rem 1.2rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .serv-border-left:before {
      display: none;
    }
    .serv-border-right:after {
      border-top: 2px solid $white;
      border-left: none;
      border-right: 2px solid $white;
      top: 42px;
      left: auto;
      right: 20px;
    }
  }
}
