.tx-cs2-accordion {
  //.accordion-title {
  //  border-bottom: 2px solid $green;
  //  i {
  //    transition: $transition-base;
  //  }
  //  &.collapsed {
  //    border-bottom: 1px solid $dark;
  //    i {
  //      transform: rotate(-180deg);
  //    }
  //  }
  //}
}

.tx-cs2-faq {

  .lead {
    position: relative;

    &:before {
      display: none;
    }
  }

  .faq-accordion {
    .accordion-item {
      margin-bottom: 2rem;

      .accordion-header {
        .accordion-title {
          position: relative;
          &:before {
            content: '-';
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 2px;
            right: calc(100% + 20px);
            width: 94px;
            height: 120%;
            color: $primary;
            font-weight: $font-weight-bolder;
            font-size: 1.5625rem;
            border-bottom: none;

            @include media-breakpoint-down(sm) {
              right: calc(100% + 14px);
              width: 50px;
            }
          }
        }

        .collapsed {
          .accordion-title {
            &:before {
              content: '+';
              border-bottom: 2px solid $black;
            }
          }
        }
      }

      .accordion-body {
        .accordion-content {
          padding-top: 1rem;
          padding-left: 26px;
          position: relative;

          &:before {
            content: '';
            position: absolute;

            bottom: 0;
            top: 1rem;
            left: 0;
            width: 2px;
            background: $primary;
          }
        }
      }
    }
  }

  .faqSlider {
    .swiper-wrapper {
      margin-top: 33px;
      margin-bottom: 33px;
    }

    .faqSwiper-nav {
      display: flex;
      width: 120px;
      justify-content: space-between;
      position: absolute;
      top: 0;
      height: 66px;
      align-items: end;
      right: 30px;

      .swiper-button-disabled {
        opacity: 1;
        visibility: visible;
      }
    }

    .slider-item {
      .card {
        border: 2px solid $black;
        .card-body {
          padding: 27px 38px;
        }
      }

      .slider-content {
        padding-left: 1rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;

          bottom: 0;
          top: 0;
          left: 0;
          width: 2px;
          background: $primary;
        }
      }
    }
  }
}
