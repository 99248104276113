.main-header {
  transition: all .35s ease-in-out;
  transform: translateY(-100%);
  border-bottom: 1px solid $gray-400;
  .white-wrapper {
    transition: $transition-background;
    background-color: rgba(255,255,255,1);
    .nav-link {
      display: flex;
      align-items: center;
      color: $body-color;
      &.active,
      &:hover {
        color: $green;
      }
    }
  }
  &.inverted:hover,
  &.show,
  &:hover {
    .white-wrapper {
      background-color: rgba(255,255,255,1) !important;
    }
  }
  &.loaded {
    transform: translateY(0);
  }
}

.logo {
  margin-right: 1.5rem;
  opacity: 1;
  transition: $transition-fade;
  max-width: 189px;
  display: inline-block;
  @include media-breakpoint-down(md) {
    max-width: 50%;
  }

  --logo: #00802F;
  svg {
    #Text_Submarke {
      fill: $body-color;
    }
    #Logo {
      .cls-1 {
        fill: currentColor;
      }
    }
  }
}
.hideme {
    margin-top: calc(var(--meta) - 3px);
    @include media-breakpoint-down(md) {
      margin-top: calc(var(--corporate) - 3px);
    }
}
.hamburger {
  border: none;
  .hamburger-inner {
    transition-property: color,transform;
  }
  .hamburger-inner::after {
    width: 20px;
    right: 0;
  }
  &.is-active {
    .hamburger-inner::after {
      width: 28px
    }
  }
}

.navbar-expand-xl .navbar-nav {
  .level-1 {
    @extend .font-size-ui-nav;
    transition: $transition-base;
    .nav-link {
      position: relative;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      &:hover,
      &.active {
        color: $green;
        &:after {
          display:block;
          content:"";
          height: 4px;
          position:absolute;
          background: $green;
          width: calc(100% - 2.5rem);
          bottom: -2px;
          left: 1.25rem
        }
      }
      @include media-breakpoint-down(lg) {
        font-size: $font-size-smallBig;
        line-height: 1.2;
        font-weight: 300;
        padding: .5rem 0;
        &.active,
        &:hover {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

#langsDropdown {
  &:after {
    display: none;
  }
  &:before {
    font-family: 'Material Icons';
    vertical-align: -3px;
    content: "public";
    border: none;
    color: inherit;
    margin-right: .5rem;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
  }
}
.close-flyout {
  position: absolute;
  right: 0;
  z-index: 1;
}
.material-icons.close-flyout,
.service-nav-wrapper {
  opacity: 0;
  transition: $transition-fade,$transition-collapse;
}
.show {
  .material-icons.close-flyout,
  .service-nav-wrapper {
    opacity: 1;
  }
}
#flyout-menu {
  /*width: 100%;*/
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
  /*height: 0;*/
  max-height: calc((var(--vh) * 100) + var(--meta) - 80px);
  right:0;
  left:0;
  @include media-breakpoint-up(xxl) {
    left: calc((100vw - 1457px) / 2);
    right: calc((100vw - 1457px) / 2);
  }
  margin-top: 1px;
  transition: $transition-fade;
  &.show {
    height: auto;
    pointer-events: all;
    z-index: $zindex-fixed;
  }

  .collapsing:not(.opened) {
    height: var(--lasth);
  }
  .opened {
    display: block;
    transition-delay: .150s;
    &.show {
      height: auto;
    }
  }
  &.fakeScroll {
    .fakeScroll__wrap {
      height: 0;
    }
  }
  &.show.fakeScroll {
    .fakeScroll__wrap {
      height: 100%;
    }
  }
  &.show {
    @include media-breakpoint-down(lg) {
      height: calc(var(--vh) * 100 + var(--meta));
      max-height: calc(var(--vh) * 100 + var(--meta));
      width: 100vw;
      margin-top: 1px;
      left: 0;
      right: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    background: $white;
    overflow-y: auto;
    .submenu-item {
      transition: transform .3s ease;
    }
    .submenu-item.show,
    .collapsing-wrapper,
    > .content-wrapper,
    > .content-wrapper > .container-fluid {
      min-height: 100%;
      height: auto;
    }
    .sublevel-wrap {
      height: 100%;
      top: 0;
      opacity: 0;
      right: -100vw;
      position: absolute;
      width: 100%;
      background: $white;
      transition: $transition-base;
      visibility: hidden;
      flex-wrap: nowrap;
      &.moved-left {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
    .submenu-item.show > .nav {
      position: static;
      height: 100%;
      margin: 0;
      > .nav-item {
        position: static;
        padding: 0;
        &.parent-level {
          padding: 3.5rem 0;
          @include media-breakpoint-down(sm) {
            padding: 3rem 0;
          }
        }
      }
    }
    .moved-left {
      transform: translateX(-100vw);
    }
  }
}
.bg-beige {
  background: $beige;
}
.collapse-nav {
  padding-bottom: .25rem;
  .collapse-trigger {
    display: flex;
    align-items:center;
    justify-content: space-between;
    border-bottom: 2px solid $darkgreen;
    transition: border-bottom-width .35s ease,border-bottom-color .35s ease;
    &:after {
      @extend .icon-5;
      font-family: 'Material Icons';
      vertical-align: 0;
      content: "expand_more";
      border: none;
      color: $black;
      margin-right: -.5rem;
      text-transform: none;
      font-weight: 400;
      transform: rotate(-180deg);
      transition: $transition-base;
    }
    &.collapsed {
      border-bottom: 1px solid $dark;
      &:after {
        transform: rotate(0);
      }
    }
  }
  .nav {
    flex-direction: column;
    background: $white;
    padding: 2rem;
  }
}

.search-box {
  position: relative;
  .form-control {
    padding: .313rem 5rem .313rem .9375rem;
    border-color: $gray-400;
    background-color: transparent;
  }
  .btn {
    position: absolute;
    z-index:1;
    right: 0;
    top: 0;
    height: 100%;
    width: 2.5rem;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.big {
    .btn {
      width: 2.5rem;
      &.clearinput {
        right: 2.4rem;
      }
    }
    .form-control {
      padding: .535rem 5rem .535rem .9375rem;
      border-color: $dark;
    }
    @include media-breakpoint-up(md) {
      .btn {
        width: 4rem;
        &.clearinput {
          right: 3.9rem;
        }
      }
      .form-control {
        padding: 1.22rem 8rem 1.22rem .9375rem;
      }
    }
  }
}

.Metanavigation {
  .search {
    &.inactive {
      .tx-solr {
        opacity: 0;
      }
      span {
        display: block;
      }
    }
    span {
      display: none;
      cursor: pointer;
    }
    .tx-solr {
      opacity: 1;
      transition: $transition-base;
      .desktop {
        .search-box {
          min-width: 452px;
          #searchInput {
            background-color: $white;
            border-color: $white;
            box-shadow: none;
            &:focus {
              border-color: $white;
            }
          }
          .btn {
            border: 1px solid $white;
            padding: 0 10px;
            box-shadow: none;
            z-index: 10001;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
      .tx-solr-autosuggest {
        width: 100% !important;
      }
    }
  }
}
.mobile {
  .search-box {
    border-color: $white;
    &:active,
    &:focus {
      border-bottom: 1px solid $green;
      box-shadow: none;
    }
  }
}

.corporate {
  background-color: rgba($light,1);
  transition: $transition-background;
}
.corporatenav {
  .nav-link {
    display: flex;
    align-items: center;
  }
  .material-icons {
    font-size: 1rem;
    margin-left: .25rem;
  }
}

.langdropdown {
  min-width: unset;
  margin: 0;
  padding: 0;
  top: -100 !important;
  right: 0 !important;
  transform: none !important;
  border: 1px solid $gray-400;
  box-shadow: none;
  z-index: 10000;
  .dropdown-item {
    &.active,
    &:hover {
      color: $green !important;
      background: none;
    }
  }
  .dropdown-toggle {
    z-index: $zindex-dropdown !important;
  }
}
.institutes {
  .institutesdrop {
    box-shadow: none;
    max-height: 60vh;
    right: 0 !important;
    left: auto !important;
    margin: 0;
    background: $light;
    overflow: hidden !important;
    border-top: 2.5rem solid $light;
    border-bottom: 2.5rem solid $light;
    @include media-breakpoint-down(sm) {
      max-width: calc(100vw - 32px);
      max-height: calc(var(--vh) * 80);
    }
    @include media-breakpoint-down(xs) {
      max-width: 94vw;
      min-width: 94vw;
      /*right: -8px !important;*/
      max-height: calc((var(--vh) * 100) - 64px);
    }
    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .dropdown-toggle {
    z-index: $zindex-dropdown;
    &:after {
      font-size: 1rem !important;
      color: inherit !important;
    }
  }
  &.show {
    .dropdown-toggle {
      color: $black;
    }
  }
  .list-group {
    display: grid;
    grid-template-columns: max-content;
    @include media-breakpoint-down(md) {
      grid-template-columns: auto;
    }
    gap: 1rem;
    .list-group-item {
      background: none;
      display: grid;
      grid-template-columns: 1fr 3fr;
      padding: 0;
      border: none;
      gap: 2rem;
      div:first-child {
        white-space: nowrap;
      }
      div:last-child {
        white-space: nowrap;
        /*min-width: 240px;*/
        @include media-breakpoint-down(xs) {
          min-width: 180px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

.collapsing-wrapper {
  display: grid;
  > div {
    grid-row: 1;
    grid-column: 1;
  }
}
.inverted{
  .main-header:not(.show) {
    border-color: $white;
    .white-wrapper {
      background-color: rgba(255,255,255,0);
    }
    .corporate {
      background-color: transparent;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background-color: $white;
    }
    *::placeholder,
    *:not(.inv-exclude) {
      color: $white;
      border-color: $white;
      --logo: #FFFFFF;
    }
    svg {
      #Text_Submarke {
        fill: currentColor;
      }
      #Logo {
        fill: currentColor;
        .cls-1 {
          fill: currentColor;
        }
      }
    }
    &:hover {
      *::placeholder,
      * {
        color: initial;
        border-color: initial;
        --logo: #00802F;
      }
      /*border-color: initial;*/
      .corporate {
        background-color: $light;
      }
      .hamburger-inner,
      .hamburger-inner:after,
      .hamburger-inner:before {
        background-color: $black;
      }
    }
  }
}


%nav-p {
  @include media-breakpoint-down(lg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  @include media-breakpoint-down(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.mainnav-wrap {
  @include media-breakpoint-down(lg) {
      width: 100vw;
      position: relative;
      #mainnav {
        background: $white;
        width: 100%;
        position: fixed;
        max-height: calc((var(--vh) * 100) + var(--meta));
        top: calc(-1 * var(--meta));
        overflow-y: auto;
        margin-top: 1px;
        left: 0;
      }
      .top-bar-section {
        border-bottom: 1px solid $gray-400;
      }
   }
}
.hamburger-wrapper-in {
  transition: $transition-base;
  position: absolute;
  right: 0;
  width: 0;
  min-width: 80px;
}
.hamburger-wrapper {
  &.show {
    .logo {
      opacity: 0;
      visibility: hidden;
    }
    .m-search {
      width: 86%;
    }
    .closelevel.show {
      display: block;
      width: 80%;
    }
    .hamburger-wrapper-in {
      width: 100%;
    }
  }
}
.h4-xl-underline {
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $dark;
    padding-bottom: 1rem;
    font-size: $font-size-smallBig;
    line-height: 1.2;
    font-weight: 300;
    margin-bottom: .5rem;
    color: $black;
  }
}
.closelevel {
  display: none;
  width: 0;
  transition: width .3s ease;
}
.hide {
  display: none;
}
.parent-level {
  padding: 3.5rem 0;
  width: 100%;
  h4 {
    font-size: $font-size-smallBig;
  }
  border-bottom: 1px solid $gray-400;
  @include media-breakpoint-up(sm) {
    padding: 3rem 0;
  }
}
/*breadcrumb*/
#breadcrumb {
  z-index: 2;
  .dots-wrap {
    display: none;
  }
  &.shrink {
    .dots-wrap {
      display: inline-block;
    }
    .breadcrumb-item {
      &.inlist {
        display: none;
      }
    }
  }
}
.breadcrumb-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0.875rem;
  @include media-breakpoint-down(sm) {
    font-size: 15px;
  }
  .dropdown-menu {
      padding: 0;
      border: 1px solid $gray-400;
      margin-top: -1px;
      margin-right: -1px;
  }
  .mask {
      display: block;
      background: $white;
      height: 6px;
      z-index: 10001;
      position: absolute;
      display: none;
  }
  .dropdown-item {
    &:hover ,
    &.active {
      background: $green;
      color: $white;
    }
  }
  > a {
    color: $dark;
    text-decoration: none;
    @extend %navhover;
  }
  &.active {
    > a {
      color: $black;
    }
  }
  &.show {
    position: static;
    > a {
      color: $green;
    }
    .mask {
      display: block;
    }
  }
  @extend .font-size-ui-small;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">"
}
.extra {
  .breadcrumb-item {
    padding: .5rem !important;
    margin: 0 .75rem 0 .75rem;
    border: 1px solid transparent;
    &.show {
      border-color: $gray-400 $gray-400 $white $gray-400;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: none;
    position: absolute;
    margin-left: -1.75rem;
    width: 16px;
    padding: 0;
  }
  .dropdown-item {
    color: $dark;
    @extend .font-size-ui-small;
    padding: .5rem .75rem;
  }
}

.br-trigger {
  position: relative;
  margin-left: .5rem;
  width: 20px;
  height:20px;
  transition: all .2s ease;
  &:before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid $gray-400;
    position:absolute;
    font-family: 'Material Icons';
    content: "expand_more";
    font-size: 1rem;
    color: $black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
.show {
  .br-trigger {
    &:before {
      transform: rotate(-180deg);
    }
  }
}

.servicenav {
  .nav {
    padding-top: 1.5rem 2rem;
  }
  .nav-link {
    @extend .font-size-ui;
    @extend %navhover;
    padding: .5rem 0;
    color: $black;
  }
}
.btn.clearinput {
  position: absolute;
  display: none;
  right: 2.4rem;
  z-index: 1;
}

#searchInput {

}

.main-header {

  position: absolute;
  width: 100%;
  z-index: 2000;

  .langNavigation {
    .langnavigation {
      .langnavigation-item {
        position: relative;
        color: $white;
        padding: 0 4px;
        text-transform: uppercase;
        font-weight: $font-weight-bolder;
        font-size: 16px;
        display: inline-block;
        text-align: center;
        min-width: 24px;
        &:hover {
          text-decoration: none;
        }

        &.active {
          font-size: 20px;
          &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $white;
          }
        }
      }
    }
  }

  .desktop-view {
    .header-top {
      padding-top: 10px;
      padding-bottom: 10px;

      .Metanavigation {
        .search {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          span {
            line-height: 0;

            i {
              color: $white;
              font-size: 16px !important;
            }

            .svg-icon {
              width: 13px;
              height: 13px;
            }
          }
        }
        .metanav {
          .nav-item {
            padding: 0;
            margin-right: 1rem;

            a.nav-link {
              color: $white;
              font-weight: $font-weight-bolder;
              padding: .5rem .8rem;
            }

            &.last {
              a.nav-link {
                border: 2px solid $white;
              }
            }
          }
        }
      }
    }

    .header-middle {
      background-color: $white;
      transition: $transition-base;
      z-index: 4000;

      .MainNavigation {
        .navbar-nav {
          display: flex;
          flex-direction: row;

          .nav-item.level-1 {
            padding: 0 1.75rem;

            .nav-link {
              color: $black;
              transition: $transition-base;
            }

            &:hover, &.collapsed {
              & > .nav-link {
                color: $primary;
              }
            }

            &.collapsed {
              .ul-level-2 {
                //visibility: visible;
                opacity: 1;
                height: calc(100vh - 152px);
              }

              &.scrollbar {
                .ul-level-2 {
                  overflow-y: auto;
                }
              }
            }
          }
        }

        .ul-level-2 {
          display: block;
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 5000;
          background: white;
          bottom: auto;
          top: 100%;
          height: 0;
          padding: 0;
          transition: height .5s ease-in-out, opacity 0.3s ease-in-out;
          overflow-y: hidden;
          overflow-x: hidden;

          &::-webkit-scrollbar-track
          {
            background-color: #F5F5F5;
          }

          &::-webkit-scrollbar
          {
            width: 8px;
            background-color: #F5F5F5;
          }

          &::-webkit-scrollbar-thumb
          {
            background-color: $secondary;
            border-radius: 10px;
          }

          .level-2-title {
            background-color: $black;

            .content-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              padding-top: 8px;
              padding-bottom: 8px;

              h2 {
                margin-bottom: 0;
                color: $white;
                width: auto;
                position: relative;
                height: fit-content;

                &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  background-color: $white;
                }
              }

              .level-2-close {
                color: $white;
                width: 120px;
                cursor: pointer;

                .svg-icon {
                  height: 4rem;
                  width: 5.4375rem;
                }
              }
            }
          }

          li, ul {
            text-decoration: none;
            list-style-type: none;
          }

          .nav-item.level-2 {
            padding-top: 1.5rem;
            position: initial;

            & > a.nav-link {
              color: $black;
              font-size: 30px;
              //text-transform: uppercase;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: $black;
              }
            }
          }

          .ul-level-3 {
            padding-left: 0;
            padding-top: 1rem;
            .nav-item.level-3 {
              & > a.nav-link {
                color: $black;
                font-size: 30px;

                &:hover {
                  color: $primary;
                }

                &:not(.has-sub) {
                  .svg-icon {
                    display: none;
                  }
                }
                .svg-icon {
                  width: 1.25rem;
                  height: 1.25rem;
                  margin-left: 1rem;
                  color: $gray-600;
                  --color1: #9B9B9B;
                }
              }
            }

            .nav-item.level-3 {
              &.collapsed {
                .ul-level-4 {
                  //visibility: visible;
                  opacity: 1;
                  height: calc(100vh - 148px);
                }
              }
            }

            .ul-level-4 {
              display: block;
              opacity: 0;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 5200;
              background: white;
              bottom: auto;
              top: 0;
              height: 0;
              padding: 0;
              transition: height .5s ease-in-out, opacity 0.3s ease-in-out;
              overflow: hidden;

              .level-4-header {
                .content-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-bottom: 2rem;

                  .level-4-close {
                    width: 120px;
                    cursor: pointer;

                    .svg-icon {
                      height: 4rem;
                      width: 5.4375rem;
                      color: $black;
                      --color1: #000;
                    }
                  }
                  .level-4-back {
                    color: $black;
                    cursor: pointer;
                    margin-right: 80px;

                    .svg-icon {
                      height: 23px;
                      width: 23px;
                      margin-right: 20px;
                      color: $black;
                      --color1: #000;
                    }
                  }
                }
              }

              .level-4-title {
                margin-bottom: 2rem;

                h2 {
                  margin-bottom: 0;
                  color: $primary;
                  width: fit-content;
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: calc(100% + 50px);
                    height: 1px;
                    background-color: $black;
                  }
                }
              }

              .nav-item.level-4 {
                .nav-link {
                  transition: $transition-base;
                  &:hover {
                    color: $primary;
                  }
                }
              }
            }

          }
        }
      }
    }
  }


  .mobile-view {

    .langNavigation {
      .langnavigation {
        .langnavigation-item {
          color: $black;

          &.active {
            color: $primary;
            &:after {
              background-color: $primary;
            }
          }
        }
      }
    }

    .header-top {
      padding-top: 40px;
      padding-bottom: 10px;
      background: $white;

      transition: padding-top .2s ease-in-out;

      .Metanavigation {
        .search {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          span {
            line-height: 0;

            i {
              color: $white;
              font-size: 16px !important;
            }
          }
        }
        .metanav {
          .nav-item {
            padding: 0;
            margin-right: 1rem;

            a.nav-link {
              color: $white;
              font-weight: $font-weight-bolder;
              padding: .5rem .8rem;
            }

            &.last {
              a.nav-link {
                border: 2px solid $white;
              }
            }
          }
        }
      }
    }

    .hamburgerMenuContent {
      background-color: $white;
      z-index: 4000;
      position: absolute;
      opacity: 0;
      width: 100%;
      top: 0;
      left: -100%;
      bottom: auto;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      transition: left .3s ease-in-out, opacity .3s ease-in-out;

      &.collapsed {
        opacity: 1;
        left: 0;
      }

      .search {
        .material-icons {
          color: $black
        }
      }

      .hamburger-close {
        .svg-icon {
          width: 86px;
          height: 63px;
        }

        &.hidden {
          visibility: hidden;
          opacity: 0;
        }
      }

      .MainNavigation {
        .navbar-nav {
          display: flex;
          flex-direction: column;

          padding-top: 75px;

          .nav-item.level-1 {
            padding: 0 .8rem;

            .nav-link {
              color: $black;
              font-size: 1.5625rem;
              //text-transform: uppercase;
              transition: $transition-base;
            }

            &:hover, &.collapsed {
              & > .nav-link {
                color: $primary;
              }
            }

            &.collapsed {
              .ul-level-2 {
                //visibility: visible;
                opacity: 1;
                height: calc(100% - 86px);
              }
            }
          }
        }

        .ul-level-2 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 5000;
          background: $white;
          bottom: auto;
          top: 86px;
          height: 0;
          padding: 0;
          transition: height .5s ease-in-out, opacity 0.3s ease-in-out;
          overflow: hidden;

          & > .content-wrapper {
            height: 100%;
          }

          .level-2-title {
            background-color: $black;
            margin-bottom: 1rem;

            .content-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              padding-top: 8px;
              padding-bottom: 8px;

              h2 {
                margin-bottom: 0;
                color: $white;
                width: auto;
                position: relative;
                height: fit-content;

                &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  background-color: $white;
                }
              }

              .level-2-close {
                color: $white;
                width: 120px;
                cursor: pointer;
                justify-content: flex-end;

                .svg-icon {
                  height: 4rem;
                  width: 5.4375rem;
                }
              }
            }
          }

          .level-2-footer {
            .level-2-back {
              color: $primary;
              cursor: pointer;
              font-weight: $font-weight-bolder;
              margin-right: 80px;

              .svg-icon {
                height: 23px;
                width: 23px;
                margin-right: 20px;
                color: $primary;
                --color1: #D70814;
              }
            }
          }

          li, ul {
            text-decoration: none;
            list-style-type: none;
          }

          .nav-item.level-2 {
            & > a.nav-link {
              color: $black;
              font-size: 22px;

              &:hover {
                color: $primary;
              }
            }

            &.collapsed {
              .ul-level-3 {
                //visibility: visible;
                opacity: 1;
                height: 100%;
              }
            }
          }

          .ul-level-3 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5500;
            background: $white;
            bottom: auto;
            top: 0;
            height: 0;
            padding: 0;
            transition: height .5s ease-in-out, opacity 0.3s ease-in-out;
            overflow: hidden;

            & > .content-wrapper {
              height: 100%;
            }

            .level-3-header {
              .content-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 2rem;
                margin-top: 2rem;

                h2 {
                  margin-bottom: 0;
                  color: $primary;
                  width: 100%;
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: calc(100%);
                    height: 1px;
                    background-color: $black;
                  }
                }
              }
            }

            .level-3-footer {
              .level-3-back {
                color: $primary;
                cursor: pointer;
                font-weight: $font-weight-bolder;
                margin-right: 80px;

                .svg-icon {
                  height: 23px;
                  width: 23px;
                  margin-right: 20px;
                  color: $primary;
                  --color1: #D70814;
                }
              }
            }

            .nav-item.level-3 {
              & > a.nav-link {
                color: $black;
                font-size: 22px;

                &:hover {
                  color: $primary;
                }
              }
            }

            .nav-item.level-3 {
              &.collapsed {
                .ul-level-4 {
                  //visibility: visible;
                  opacity: 1;
                  height: calc(100vh - 86px);
                }
              }
            }

            .ul-level-4 {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              opacity: 0;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 5600;
              background: $white;
              bottom: auto;
              top: 0;
              height: 0;
              padding: 0;
              transition: height .5s ease-in-out, opacity 0.3s ease-in-out;
              overflow: hidden;

              & > .content-wrapper {
                height: 100%;
              }

              .level-4-header {
                .content-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 2rem;
                  margin-top: 2rem;

                  h2 {
                    margin-bottom: 0;
                    color: $primary;
                    width: 100%;
                    position: relative;

                    &:after {
                      content: '';
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      width: calc(100%);
                      height: 1px;
                      background-color: $black;
                    }
                  }
                }
              }

              .level-4-footer {
                .level-4-back {
                  color: $primary;
                  cursor: pointer;
                  font-weight: $font-weight-bolder;
                  margin-right: 80px;

                  .svg-icon {
                    height: 23px;
                    width: 23px;
                    margin-right: 20px;
                    color: $primary;
                    --color1: #D70814;
                  }
                }
              }

              .nav-item.level-4 {
                .nav-link {
                  transition: $transition-base;
                  font-size: 15px;
                  font-weight: $font-weight-bolder;

                  &:hover {
                    color: $primary;
                  }
                }
              }
            }

          }
        }
      }

      .Metanavigation {
        .metanav {
          .nav-item {
            padding: 0;

            a.nav-link {
              color: $primary;
              font-weight: $font-weight-normal;
              padding: .5rem .8rem;
            }
          }
        }
      }
    }

    .header-top {
      .search {
        .material-icons {
          color: $black;
        }
      }
    }

    .hamburger-mobile {
      .svg-icon {
        position: absolute;
        height: 23px;
        width: 23px;
        top: -6px;
        right: 6px;
        transform: rotate(-45deg);
      }
    }
  }

  &.sticky {
    position: fixed;

    .header-middle {
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
    }

    .mobile-view {
      .header-top {
        padding-top: 10px;
      }
    }
  }

  //&.hideme {
  //  @include media-breakpoint-down(md) {
  //    margin-top: 0 !important;
  //  }
  //}

  &.header-collapsed {
    position: fixed;
    height: 100vh;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;

    &.hideme {
      margin-top: 0 !important;
    }
  }
}

body.header-collapsed {
  overflow: hidden;
  height: 100vh;

  //&::-webkit-scrollbar {
  //  display: none;
  //}
}

.keyvisual_box {
  position: relative;
  padding-top: calc(-1 * (var(--meta) + var(--headerMidd)));
  aspect-ratio: 2.3341;
  @include media-breakpoint-down(sm) {
    .h-gal {
      height: 157vw;
      aspect-ratio: auto;
    }
  }

  .SocialNavigation {
    position: absolute;
    top: 0;
    height: 100%;
    right: 30px;
    display: flex;
    align-items: center;
    z-index: 1;
    .metanav {
      .nav-item {
        margin-bottom: 20px;
        .svg-icon {
          width: initial;
          height: initial;
        }
      }
    }

    .nav-item a:hover {
      .svg-icon path {
        fill: $black;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: flex-end;
      margin-bottom: 20px;
      right: 16px;

      /*.svg-icon path {
        fill: $white;
      }*/
    }
  }
  .keyvisual_titles {
    top: 0;
    width: 100%;
    padding-top: calc(-1 * (var(--meta) + var(--headerMidd)));
    @include media-breakpoint-down(md) {
      margin-left: 16.66667%;
      width: 83.33333%;
    }
  }
  &.small {
    aspect-ratio: auto;
    .h-gal {
      height: 340px;
      @include media-breakpoint-down(sm) {
        height: 360px;
      }
    }
    .SocialNavigation {
      right: 22px;
      @include media-breakpoint-down(md) {
        align-items: center;
      }
      .nav-item {
        &.last {
          margin-bottom: 0;
        }
      }
    }
    .keyvisual_titles {
      h1 {
        font-size: 4rem;
        text-shadow: 2px 2px 14px rgba(255, 255, 255, 0.50), 0px -2px 10px rgba(255, 255, 255, 0.50);
        line-height: 1.1;
        @include media-breakpoint-down(md) {
          font-size: 35px;
        }
      }
      .lead {
        text-shadow: 2px 2px 14px rgba(255, 255, 255, 0.50), 0px -2px 10px rgba(255, 255, 255, 0.50);
      }
      @include media-breakpoint-down(md) {
        margin-left: 0;
        width: 100%;
        h1 {
          padding-left: 6px;
        }
        .lead {
          margin-left: 60px;
          &:before {
            width: 50px;
          }
        }
      }
    }
  }
}
