.main-footer {
  position: relative;
  z-index: 0;
  padding: 0;
  color: $white;
  .col-y-padding {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    @include media-breakpoint-down(sm) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .col-x-padding {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  p {
    margin-bottom: 1.5rem;
  }
  a:not(.btn) {
    color: $white;
  }
  .text-black {
    a:not(.btn) {
      color: $black;
    }
  }
  .footerbar-nav {
    padding: 5px 0 8px 0;
    .nav-item {
      .nav-link {
        color: $white;
        padding: 0;
        font-weight: bold;
      }
      & + .nav-item {
        .nav-link {
          @include media-breakpoint-up(md) {
            margin-left: 4.375rem;
          }
        }
      }
    }
  }
  form {
    .form-group {
      .form-control {
        font-weight: bold;
        color: $black;
        font-size: 1.25rem;
        border: 2px solid $black;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        height: initial;
        &::placeholder {
          font-weight: bold;
          color: $black;
          font-size: 1.25rem;
        }
      }
      .btn {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
  .footer-social {
    .nav-item {
      a {
        .svg-icon {
          width: 2rem;
          height: 2rem;
        }
      }
      & + .nav-item {
        margin-top: 2.875rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .m-100 {
      width: 100% !important;
    }
  }
  .footer-item {
    h3 {
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        .svg-icon {
          width: 1.25rem;
          height: 1.25rem;
          transform: rotate(-90deg);
          transition: $transition-base;
        }
      }
      &.collapsed {
        .svg-icon {
          transform: rotate(90deg);
          transition: $transition-base;
        }
      }
    }

    .collapse {
      @include media-breakpoint-up(md) {
        display: block;
        height: auto !important;
        visibility: visible;
      }
    }
    .collapsing{
      @include media-breakpoint-up(md) {
        position: relative;
        height: unset !important;
        overflow: hidden;
      }
    }
    .form-group {
      &:last-child {
        margin: 0;
      }
    }
  }
}
.fa-stack-125x,
.fa-stack-15x,
.fa-stack-175x {
  @extend .fa-stack-1x;
}
.fa-stack-125x,
.fa-125x {
  font-size: 1.25em;
}
.fa-stack-15x,
.fa-15x {
  font-size: 1.5em;
}
.fa-stack-175x,
.fa-175x {
  font-size: 1.75em;
}
.fab.fa-rss {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.scroll-top {
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  border: 2px solid $secondary;
  background-color: $white;
  padding: 1rem 1.5rem;
  transition: $transition-base;

  .svg-icon {
    width: 2.875rem;
    height: 2.875rem;
    color: $secondary;
    --color1: #9B9B9B;
    transition: $transition-base;
  }

  &:hover {
    border: 2px solid $black;
    .svg-icon {
      color: $black;
      --color1: #000;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 6px 12px;
    right: 12px;
    transform: translateY(-80%);
    .svg-icon {
      width: 21px;
      height: 21px;
    }
  }
}

.secondary-after,
.black-after{
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    @include media-breakpoint-down(sm) {
      content: none;
    }
  }
}

.red-before {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    @include media-breakpoint-down(sm) {
      content: none;
    }
  }
}
.red-before {
  &:before {
    background-color: $red;
    right: 100%;
  }
}
.secondary-after {
  &:after {
    background-color: $secondary;
    left: 100%;
  }
}
.black-after {
  &:after {
    background-color: $black;
    left: 100%;
  }
}

.footer-redesign {
  .footer-border-top {
    border-top: 1rem solid $primary;
  }
  .footer-social {
    .nav-item {
      a {
        .svg-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      & + .nav-item {
        margin-left: 1.5rem;
        margin-top: 0;
      }
    }
  }
  .list-footer {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      padding: 4px 0;
    }
  }
  .rte {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h4 {
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
}
