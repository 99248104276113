.tx-cs2-eventsElement {

  .filter-category {
    .form-control {
      border: 2px solid $black;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.5rem;
      height: auto;
      padding-top: 9px;
      padding-bottom: 9px;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }

  .img-button {
    position: absolute;
    bottom: 20%;
    right: 0;
    @include media-breakpoint-down(sm) {
      bottom: 20px;
    }
  }

  .events-content {
    position: relative;
    padding: 1.5rem;
    text-align: left;
    background-color: transparent;

    h2:after {
      background-color: $black !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      h2 {
        font-weight: $font-weight-bolder;
        &:after {
          display: none;
        }
      }
      p,h3 {
        margin-bottom: 10px;
      }
      .shorttext {
        margin-bottom: 20px;
      }
    }

    .content-position {

      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .events-grid {
    .events-content {
      padding: 0;

      .content-position {
        padding: 0;
      }
    }

    .grid-col-2, .grid-col-3 {
      margin-top: 4rem;
    }
  }

  .title-hr {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      position: initial;
    }

    &:after {
      right: calc(100% + 30px);
      width: 212px;

      @include media-breakpoint-down(sm) {
        right: 0;
        left: auto;
        height: calc(100% + -108px);
        width: 2px;
        bottom: 108px;
        top: auto;
      }
    }
  }

  .event-detail {
    .detail-content{
      margin-top: 2rem;
    }

    .detail-footer {
      margin-top: 8rem;
    }

    .detail-buttons {
      margin-top: 5rem;
    }

    .event-icon {
      margin-bottom: 3rem;
    }

    @include media-breakpoint-down(sm) {
      .detail-content, .detail-footer, .detail-buttons {
        margin-top: 1rem;
      }
      .event-icon {
        margin-bottom: 1rem;
      }
    }

    .links {
      .col-bg {
        z-index: -1;
        img {
          filter: brightness(0.5);
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(2px);
        }
      }

      .col-content {
        padding: 2rem 3rem;
      }
    }

    .event-icon {
      .title-box {
        max-width: 100px;
        .title {
          font-size: 1.25rem;
          font-weight: $font-weight-bolder;
        }
      }

      .icon-box {
        position: relative;
        .svg-icon {
          height: 3.4375rem;
          width: 2.9375rem;
          position: absolute;
          top: -15px;
          right: 5px;
          z-index: 2;
        }

        &:after {
          content: '';
          position: relative;
          display: block;
          height: 2.785rem;
          width: 2.785rem;
          background-color: $secondary;
        }
      }
    }

    .content-position {
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 1rem 2rem 0 2rem !important;
      }
      .title-hr {
        &:after {
          content: '';
          position: absolute;
          display: block;

          @include media-breakpoint-down(sm) {
            right: 15px;
            left: auto;
            height: 100%;
            width: 2px;
            bottom: auto;
            top: 0;
            background: $black;
          }
        }
      }
    }
  }
}
