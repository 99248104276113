.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: block;
  transition: all .35s ease;
  z-index: -1;
  &:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: "";
    background: $overlay-background;
  }
  .spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $spinner-width;
    height: $spinner-height;
    stroke: $primary;
    opacity: 1;
  }
  &.show {
    z-index: 999;
    visibility: visible;
  }
}
.header-overlay {
  visibility: hidden;
  @extend .page-overlay;
  bottom: -100vh;
  width: 200vw;
  left: -50vw;
  right: auto;
}
