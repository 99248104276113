.podcast-slider {
  transition: all .3s ease;
  padding-right: 25%;
  position: relative;
  z-index: 1;
  margin-left: -100%;
  padding: 123px 25% 0 var(--gap);
  @include media-breakpoint-down(md) {
    padding-top: 76px;
    padding-right: 15%;
  }
  height: calc(100% - 210px);
  .swiper-slide {
    .podcast-item {
      transition: all .3s ease;
      scale: .9;
      opacity: .5;
      .ratio-podcast {
        &:after {
          padding-bottom: 97.87%;
        }
        @include media-breakpoint-down(md) {
          &:after {
            padding-bottom: 68.55%;
          }
        }
      }
      .art-wrap {
        max-width: 33%;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }
    }
    &.swiper-slide-active {
      .podcast-item {
        scale: 1;
        opacity: 1;
        .ratio-podcast {
          &:after {
            padding-bottom: 76.72%;
          }
          @include media-breakpoint-down(md) {
            &:after {
              padding-bottom: 68.55%;
            }
          }
        }
        .art-wrap {
          max-width: 41.66667%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }
      }
    }
    &.swiper-slide-next {
      .podcast-item {
        transform-origin: left center;
      }
    }
    &.swiper-slide-prev {
      .podcast-item {
        transform-origin: right center;
      }
    }
  }

  .v-audio {
    .v-progressBar {
      background: $gray-700;
      height: 1px;
      width: 100%;
    }
    .v-time {
      color: $white;
      &.audio {

      }
    }
    .v-playPauseButton {
      border: 2px solid $primary;
      border-radius: 50%;
      width: 26px;
      height: 26px;
    }
  }
  .v-vlite {
    background: none;
    --vlite-controlBarHorizontalPadding: 0;
  }
  .playing {
    .v-paused {
      display: block;
    }
  }

}
.podcast-bg {
  filter: blur(60px);
  transform: translate(-156px,-156px);
  .ratio-podcast {
    max-width: 45.35%;
  }
}
.podcast-slider-bg {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.big-control {
  bottom: 73px;
  left: 0;
  right: 0;
  margin-top: -100px;
  z-index: 1;
  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    left: auto;
    right: auto;
    margin: 0;
    top: 0;
    width: 46px;
    height: 46px;
    &:after {
      font-size: 2.875rem;
    }
  }
  .circle-icon {
    width: 6rem;
    height: 6rem;
    margin-top: 0;
    span {
      font-size: 3rem;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 1.5rem;
    bottom: 1rem;
    .swiper-button-prev,
    .swiper-button-next {
      width: 24px;
      height: 24px;
      &:after {
        font-size: 1.125rem;
      }
    }
    .circle-icon {
      width: 4rem;
      height: 4rem;
      span {
        font-size: 2rem;
      }
    }
  }
}

.podcast-show {
  position: relative;
  z-index: 1;
}

.art-img {
  @include media-breakpoint-down(md) {
    width: 44px;
    height: 44px;
  }
}

.tx-cs2-podcastslider {
  &.podplaying {
    .podcast-slider {
      padding-right: calc(var(--gap) - (var(--gridgap) * 2));
      .art-wrap {
        max-width: 31.35%;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }
      .ratio-podcast {
        &:after {
          padding-bottom: 95.72%;
          @include media-breakpoint-down(md) {
            padding-bottom: 68.55%;
          }
        }
      }
      .podcast {
        padding-bottom: 50px;
      }
    }
    .big-control {
      display: none !important;
    }
  }
}

.podcast-slider-bg {
  .ratio-podcast {
    &:after {
      padding-bottom: 76.72%;
    }
    @include media-breakpoint-down(md) {
      &:after {
        padding-bottom: 68.55%;
      }
    }
  }
}

