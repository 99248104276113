.tx-cs2-testimonialsElement {

  .no-focuspoint.ratio-container {
    height: 100% !important;
  }

  .testimonials-content {
    position: relative;
    padding: 3.4375rem 7.5rem 2.5rem 8.4375rem;
      @include media-breakpoint-down(sm) {
        padding: 35px 20px 40px 20px;
      }
  }

  .title-hr {
    position: relative;

    @include media-breakpoint-down(sm) {
      position: initial;
      padding-left: 0;
      margin-bottom: 35px;
    }

    &:after {
      right: calc(100% + 20px);
      width: 14.375rem;

      @include media-breakpoint-down(sm) {
        content: none;
      }
    }

  }

  .testimonialsSwiper {
    .slider-image {
      @include media-breakpoint-down(sm) {
        width: 67%;
      }
    }
    .nav-position {
      position: relative;
      display: flex;
      width: 100%;
      height: 66px;

      @include media-breakpoint-up(md) {
        padding-right: 56px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }

      .testimonialsSwiper-nav {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 120px;
        height: inherit;
      }
    }
    .nav-mobile-position {
      padding: 25px 35px;
      width: 33%;
      .swiper-button-next, .swiper-button-prev {
        margin-top: 0;
        width: 52px;
        height: 62px;
      }
      .swiper-button-prev {
        margin-bottom: 15px;
      }
    }
  }
  .no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
