.tx-cs2-heroheader {
  .underlay {
    /*left: calc(-2 * var(--gridgap));*/
    max-width: 1920px;
    width: 100vw;
    margin: 0 auto;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 6.6% 88%, 0 0);
    @include media-breakpoint-down(sm) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 16% 96%, 0 60%);
    }
    background: rgba($black,.55);
    /*max-width: 1440px;
    margin: 0 auto;*/
/*    videp,
    img {
      mix-blend-mode: multiply;
    }*/
    @media (max-width: 1919.98px) {
      transform: none;
    }

  }
  .header-area {
    height: $vh100;
  }
  .controlls {
    position: absolute;
    right: 0;
    bottom: 9.5rem;
  }
}
