.input {
  position: relative;
}

.dropdown + label,
.form-control + label {
  color: $dark;
  position: absolute;
  display: inline-block;
  transition: all .2s ease;
  top: 50%;
  margin: 0;
  vertical-align: middle;
  line-height: 1;
  left: 1rem;
  transform: translateY(-50%);
  @include media-breakpoint-down(sm) {
    font-size: .875rem;
  }
}
.form-control {
  @extend %input-resize-down-sm;
  box-shadow: none;
  &:valid {
    color: $black;
    border-color: $black;
    &:focus {
      border-color: $black;
    }
  }
  &:not(:placeholder-shown),
  &.focused,
  &:focus {
    + .dropdown-menu + label,
    + label {
      top: 0;
      padding: 2px;
      background: $white;
      font-size: .875rem;
      color: $black;
      @include media-breakpoint-down(sm) {
        font-size: .75rem;
      }
    }
  }
  &.x-solr-q:placeholder-shown {
    + label {
      display: none !important;
    }
  }
  &.dropdown + label {
    top: 0;
    padding: 2px;
    background: $white;
    font-size: .875rem;
    color: $black;
    z-index: 10000;
    @include media-breakpoint-down(sm) {
      font-size: .75rem;
    }
  }
}

textarea.form-control + label {
  top: 1.5rem;
}

label,
.control-label {
  /*color: $dark;*/
  font-size: 1.25rem;
  font-weight: $font-weight-bolder;
  color: $input-label-color;
}

.custom-radio, .custom-checkbox {
  label,
  .control-label {
    font-size: 1rem;
  }
}

.MultiCheckbox, .RadioButton {
  & > .control-label {
    margin-bottom: .8rem;
  }
}

.custom-control-label {
  span {
    line-height: 1rem;
    display: inline-block;
    vertical-align: baseline;
  }
  line-height: inherit;
  margin-bottom: .5rem;
}
.help-block {
  font-weight: 500;
  margin: .5rem 0;
  padding-left: 1rem;
}
.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-file-label:after {
  background: $black;
  color: $white;
  font-family: GillSansMTPro;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  cursor: pointer;
}
.custom-file-input {
  min-height: 37px;
  height: 100%;
}
.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  outline: none;
}
.custom-select {
  padding-right: 2.75rem;
}
.hf-warning {
  display: none;
}
.has-error {
  .input {
    &:after {
      @extend .material-icon;
      content: "error";
      color: $red;
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: none;
    }
    &.SingleSelect,
    &.MultiCheckbox,
    &.RadioButton,
    &.checkbox {
      top: 0;
      &:after{
        display: none;
      }
    }
  }
  .form-label {
    color: $red;
  }
  .custom-radio,
  .custom-checkbox,
  .hf-invalid,
  .error {
    border-color: $red;
    .custom-control-label::before {
      border-color: $red;
    }
  }
  .hs-input,
  .form-control {
    border-color: $red;
    &:focus {
      border-color: $red;
    }
  }
  .hf-warning,
  .invalid-feedback {
    display: block;
    color: $red;
    padding: 0;
    margin-top: .25rem;
    padding-left: 0;
    font-size: 0.875rem;
    //@extend .font-gill;
    font-weight: 500;
  }
  .check + .invalid-feedback,
  .check + .hf-warning {
    margin-top: 0;
    margin-bottom: .35rem;
    padding-left: 0;
  }
}

.dropdown-toggle {
  &:after {
    vertical-align: 0;
    content: url('#{$icon-path}select_arrow.svg');
    border: none;
    color: $gray-600;
    --color1: #{$secondary};
    margin-right: -.5rem;
    text-transform: none;
    font-weight: 400;
  }
}

.selectpicker {
  &:focus {
    + .dropdown-toggle {
      &:after {
        color: $black;
        --color1: #000;
        transform: rotate(180deg);
      }
    }
  }
}

.custom-control-label {
  //@extend .font-gill;
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;
  .dropdown-toggle {
    transition: none !important;
  }
  &.show {
    .dropdown-toggle {
      box-shadow: none !important;
      z-index: 1000;
      &:after {
        transform: rotate(-180deg);
      }
    }
    .inner {
      display: block;
    }
  }

  .dropdown-menu.v1 {
    min-width: calc(100% - 4rem);
    box-sizing: border-box;
    left: 1rem !important;
    border: 1px solid $gray-400;
    padding: .5rem 0;
    &[x-placement^="top"] {
      top: 2.25rem !important;
    }
    &[x-placement^="bottom"] {
      top: -2.25rem !important;
    }
    &.show {
      z-index: 10001;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid $gray-400;
    padding: .5rem 0;
    &[x-placement^="top"] {
      top: 2px!important;
    }
    &[x-placement^="bottom"] {
      top: -2px !important;
    }
    &.show {
      z-index: 10001;
    }
  }

}

.floating-focus-enabled .dropdown-toggle.focus {
  outline: $black solid 1px !important;
  outline-offset: 4px;
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $gray-700;
}

.bootstrap-select.is-invalid .dropdown-toggle, .error .bootstrap-select .dropdown-toggle, .has-error .bootstrap-select .dropdown-toggle, .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
  border-color: $red;
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: 0 !important;
  box-shadow: none;
}
/*.bootstrap-select.show .dropdown-toggle {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 0 1px $green;
}*/

.selectize-dropdown, .selectize-dropdown.form-control {
  z-index: 10001 !important;
}

.form-control.placeholder:placeholder-shown + label {
  display: none !important;
}
