.tx-cs2-metroteaser {
  .overlay {
    color: $white;
    left:20%;
    top: auto;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1.75rem;
    clip-path: polygon(0 55%, 100% 0, 100% 100%, 1.5% 100%);
    background: $green;
    height: 33%;
    --animate-duration: .5s;
    transform: translate3d(0, 0, 0);
  }
  .underlay {
    &:before {
      content:"";
      position:absolute;
      width:100%;
      height:100%;
      background-color: $black;
      opacity: .2;
      transition: $transition-fade;
      z-index: 1;
    }
  }
  .text-body {
    .underlay {
      &:before {
        background-color: $white;
      }
    }
  }
  .metro-item {
    min-height: 390px;
    overflow: hidden;
    animation-fill-mode: forwards;
    @include media-breakpoint-down(md) {
      min-height: 343px;
    }
    &:hover {
      .underlay {
        &:before {
          opacity: .76;
        }
      }
    }
    &.active {
      .underlay {
        &:before {
          opacity: .2;
        }
      }
    }
    &.podcast {
      .overlay {
        height: 26%;
      }
    }
    &.type-6 {
      .overlay {
        clip-path: polygon(0 70%,100% 0,100% 100%,1.5% 100%);
        left: 13%;
      }
      &.podcast {
        .overlay {
          height: 33%;
          left: 50%;
        }
      }
    }
    &.type-12 {
      .overlay {
        clip-path: polygon(0 72%,100% 0,100% 100%,1.5% 100%);
        left: 50%;
        height: 48%;
        @include media-breakpoint-down(md) {
          clip-path: polygon(0 70%,100% 0,100% 100%,1.5% 100%);
          left: 13%;
          height: 33%;
        }
      }
      &.podcast {
        .overlay {
          left: 75%;
          height: 37%;
          @include media-breakpoint-down(md) {
            left: 13%;
          }
        }
      }
    }
    &.playing {
      .underlay {
        &:before {
          opacity: 0;
        }
      }
      &.podcast {
        .overlay {
          display: none;
        }
      }
    }
    .icon-link .material-icons {
      transform: translateY(0);
    }
  }
  .metro-row {
    @include media-breakpoint-down(md) {
      display: grid;
      grid-auto-rows: 1fr;
    }
  }
}
.d-grid {
  display: grid;
}
.autorows {
  grid-auto-rows: 1fr;
}

.overlay {
  &.slideOutRight {
    transform: translate3D(var(--slideright),0,0);
    justify-content: start;
    padding: .5rem .75rem;
    .icon-link {
      transition: all .5s ease;
      margin-left: 0;
      transform: translateX(0);
      white-space: nowrap;
    }
  }
  &.slideInRight {
    .icon-link {
      margin-left: 100%;
      transform: translateX(-100%);
    }
  }
}
.type-12 {
  --slideright: 80%;
}
.type-6 {
  --slideright: 80%;
}
.type-4 {
  --slideright: 80%;
}
