.swiper-controls {
  .swiper-button-next,.swiper-button-prev,
  .swiper-pagination {
    position: relative;
    margin: 0;
    top:auto;
    bottom:auto;
    left:auto;
    right:auto;
    width: auto;
  }
  .swiper-button-next,.swiper-button-prev {
    margin-left: 1.25rem;
  }
  .fraction {
    @extend .font-size-ui;
  }
  .swiper-pagination {
    display: flex;
    aling-items:center;
    justify-content: center;
  }
}

