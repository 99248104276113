.tx-cs2-news {
  .news-list {
    .news-items {
      .news-item {
        .news-date {
          padding-bottom: 8px;
          border-bottom: 2px solid $black;
        }
        .news-teaser {
          padding-left: 1.75rem;
          border-left: 2px solid $primary;
          @include media-breakpoint-down(sm) {
            padding-left: 25px;
          }
        }
        .news-date-mobile {
          color: $primary;
          padding-bottom: 10px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 40px !important;
        }
      }
    }
  }
  .news-slider {
    position: relative;
    overflow: hidden;
    .news-image {
      .detail-link {
        bottom: 2.875rem;
        right: 0;
        @include media-breakpoint-down(sm) {
          font-size: 18px;
          bottom: 20px;
        }
      }
    }
    .news-info {
      padding-top: 5.5rem;
      @include media-breakpoint-down(sm) {
        padding-top: 40px;
        margin-bottom: 20px;
      }
      .line {
        position: relative;
        word-break: break-word;
        &:before {
          content: '';
          position: absolute;
          top: $h2-font-size;
          right: 100%;
          width: 212px;
          border-bottom: 2px solid $black;
        }
        @include media-breakpoint-down(sm) {
          &:before {
            content: none;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 4%;
          width: 2px;
          height: 100%;
          border-right: 2px solid $black;
        }
      }
    }
    .list-link {
      width: 100%;
      @include media-breakpoint-down(sm) {
        width: auto;
        margin-right: 100px;
        margin-top: 25px;
      }
    }
    .slider-navigation {
      position: absolute;
      top: 0;
      right: 8.5%;
      z-index: 10;
      .arrow {
        padding: 0.95rem 0.6rem;
        border: 2px solid $gray-600;
        .svg-icon {
          width: 2rem;
          height: 2rem;
          color: $gray-600;
          --color1: #9B9B9B;
        }
        & + .arrow {
          margin-left: 15px;
          @include media-breakpoint-down(sm) {
            margin-left: 7px;
          }
        }
        &.swiper-button-disabled {
          opacity: 0.3;
        }
      }
      @include media-breakpoint-down(sm) {
        top: auto;
        bottom: 0;
        right: 0;
        .arrow {
          padding: 8px 6px;
          .svg-icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .news-detail {
    @include media-breakpoint-down(sm) {
      .news-text {
          margin-bottom: 100px;
      }
      .news-navigation {
        .btn {
            width: 100%;
        }
      }
      .news-image {
        padding-left: 0;
        padding-right: 0;
      }
    }
    h3 {
      p:last-child {
        margin: 0;
      }
    }
  }
  .mb-10p {
    margin-bottom: 10px;
  }
}

.pagination-wrapper {
  margin-top: 7.8125rem;
  @include media-breakpoint-down(sm) {
    margin-top: 80px;
  }
  .pagination {
    .page-link {
      border: 2px solid $secondary;
      color: $secondary;
      font-size: 1.25rem;
      font-weight: 700;
      padding: 0.45rem 0.75rem;
      .svg-icon {
        width: 2rem;
        height: 2rem;
      }
      @include media-breakpoint-down(sm) {
        border: 0;
        font-size: 18px;
        padding: 0;
        .svg-icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    .page-item {
      display: flex;
      align-items: center;
      color: $secondary;
      padding: 0 7px;
      &:not(.icon) {
        &:hover,
        &.active {
          .page-link {
            color: $white;
            background-color: $primary;
            border-color: $red;
            @include media-breakpoint-down(sm) {
              color: $primary;
              border: 0;
              background: none;
            }
          }
        }
      }
      &.icon {
        padding: 0 16px;
        .page-link {
          padding: 0.95rem 0.6rem;
          border-color: $black;
          @include media-breakpoint-down(sm) {
            border: 2px solid $black;
            padding: 14px 7px;
          }
        }
      }
    }
    .page-item + .page-item {

    }
  }
}
