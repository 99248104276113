.tx-cs2-teasertiles {
  .tile-col {
    padding-top: 10px;
    padding-bottom: 10px;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    .tile-item {
      padding: 2.8125rem 0 2.8125rem 5.9375rem;
      .tile-text {
        line-height: 1.5rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 10px 0 10px 38px;
        .tile-text {
          width: 100%;
          line-height: 22px;
        }
      }
    }
  }
}
