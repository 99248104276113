.tx-cs2-sliderElement {

  .image-resize {
    width: 790px !important;
  }

  .slider-content {
    position: relative;
    padding: 1.5rem;

    @include media-breakpoint-up(md) {
      background-color: transparent;
      h2:after {
        background-color: $black !important;
      }
    }

    .content-position {
      padding-right: 40px;

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }
  }

  .title-hr {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      position: initial;
    }

    &:after {
      right: calc(100% - 62px);
      width: 212px;

      @include media-breakpoint-down(sm) {
        right: 50px;
        left: auto;
        height: calc(100% + -108px);
        width: 2px;
        bottom: 108px;
        top: auto;
      }
    }

  }

  .sliderSwiper {
    .sliderSwiper-nav {
      display: none;
    }
    .nav-position {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 80px;
      height: auto;

      @include media-breakpoint-up(md) {
        width: 120px;
        height: 66px;
        margin-bottom: 1.5rem;
      }

      .sliderSwiper-nav {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 120px;
        height: inherit;
        @include media-breakpoint-down(sm) {
          width: 80px;
          height: 48px;

          .swiper-button-next, .swiper-button-prev {
            width: 38px;
            height: 48px;

            border: 2px solid $secondary;

            img {
              height: 22px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  &.swiper-container {
    @include media-breakpoint-up(xl) {
      padding: 0 20px;
    }
  }

  .sliderPaginationSwiper {
    width: 250px;
    position: absolute;
    left: 10px;
    top: 0;
    height: 0;

    .swiper-slide {
      cursor: pointer;

      transition: filter .2s ease-in-out;
      -webkit-backdrop-filter: blur(0);
      filter: brightness(100%);

      border: 1px solid $secondary;
      width: 150px;

      &.swiper-slide-thumb-active {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        filter: brightness(45%);
        border: 1px solid transparent !important;
        width: 250px;
      }
    }
  }

  .sliderPaginationSwiper-moreButton {
    display: flex;
    justify-content: flex-end;
  }
}
