.tx-cs2-employeesElement {

  .employees {
    .img-button {
      position: absolute;
      bottom: 20%;
      right: 0;
    }

    .employee-content {
      .content-position {
        padding-right: 0;

        @include media-breakpoint-down(sm) {
          &.isEven {
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .employees-detail {
    .employee-content {
      @include media-breakpoint-down(sm) {
        padding-right: 1rem;
      }
    }
    .content-position {
      position: relative;
      padding: 1rem 2rem 0 2rem !important;

      @include media-breakpoint-down(sm) {
        padding: 1rem 1rem 0 2rem !important;
      }

      .title-hr {
        //width: fit-content;
        //margin-right: 0;
        //margin-left: auto;

        @include media-breakpoint-down(sm) {
          position: initial;
        }

        &:after {
          content: '';
          position: absolute;
          display: block;
          right: calc(100% - 40px);
          width: 170px;

          @include media-breakpoint-down(sm) {
            right: 0;
            left: auto;
            height: 100%;
            width: 2px;
            bottom: auto;
            top: 0;
            background: $black;
          }
        }
      }
    }
  }

  .categories {
    .col-bg {
      z-index: -1;
      img {
        filter: brightness(0.5);
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
      }
    }

    .col-content {
      padding: 2rem 3rem;
    }
  }

  .contact {

    a {
      color: $black;
      transition: $transition-base;

      &:hover {
        color: $primary;
      }
    }

    .contact-content {
      position: relative;
      padding: 1.5rem;
      text-align: left;

      @include media-breakpoint-down(sm) {
        text-align: right;
        padding: 1.5rem 2.5rem 1.5rem .8rem;
      }

      @include media-breakpoint-up(md) {
        background-color: transparent;

        h2:after {
          background-color: $black !important;
        }
      }

      .content-position {
        padding-right: 0;
      }
    }

    .title-hr {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-down(sm) {
        position: initial;
      }

      &:after {
        right: 100%;
        width: 212px;


        @include media-breakpoint-down(sm) {
          right: 28px;
          left: auto;
          height: calc(100% - 26px);
          width: 2px;
          bottom: 26px;
          top: auto;
        }
      }

    }

    .employees {
      @include media-breakpoint-up(md) {
        margin-top: -100px;

        .offset-top {
          margin-top: 80px;
        }
      }
    }
  }

}
