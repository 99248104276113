.tx-twwc-project {
  .table-responsive {
    table.table {
      thead tr.filter {
        td {
          padding: 12px 7px;
          select {
            max-width: 210px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 7px 7px;
          }
        }
      }
    }
  }
  .accordion-3col {
    .heading:not(.collapsed) {
      .fa-plus-circle {
        &:before {
          content: '\f056';
        }
      }
    }
  }
  td .table-responsive .table tr {
    background-color: unset !important;
  }
}
