.tx-cs2-teaserElement {

  .offset {
    //.offset-layer {
    //  &:after {
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    top: 0;
    //    background: $black;
    //    opacity: .45;
    //    transition: $transition-base;
    //    z-index: -1;
    //  }
    //
    //  &:hover {
    //
    //    &:after {
    //      z-index: 1;
    //      background: $black;
    //      opacity: 0;
    //    }
    //  }
    //}

    &.teaserSwiper {
      padding-right: 15%;
    }

    .offset-content {
      padding: 3rem 3rem 3rem 4.6875rem;
      z-index: 5;

      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);

      .btn .teaser-linkline {
        position: absolute;
        right: calc(100% - 1rem);
        width: 6rem;
        height: 2px;
        background: $white;
      }

      @include media-breakpoint-down(xs) {
        padding: 2rem 1rem;
      }
    }

    .offset-layer {

      .bg-offset {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        //.focuspoint.ratio-container {
        //  &.long:after {
        //    padding-bottom: ;
        //  }
        //
        //  &.long:after {
        //    padding-bottom: ;
        //  }
        //}

        img {
          z-index: -1;
          filter: brightness(45%);
          transition: -webkit-filter .2s ease-in-out;
        }
      }
      //&:hover {
      //  @include media-breakpoint-up(md) {
      //    .bg-offset img {
      //      filter: brightness(100%);
      //    }
      //  }
      //}
    }

    .btn-primary {
      width: fit-content;
      &:before {
        margin-left: 20px;
      }
    }
  }

  .structured {

    @include media-breakpoint-between(md, lg) {
      & > .row {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .btn-teaser-structured {
      position: absolute;
      right: 0;
      left: auto;
      bottom: 20%;
    }
    .serv-border {
      @include media-breakpoint-down(sm) {
        &-right:after, &-left:before {
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          border-right: none;
          border-bottom: none;
          top: 42px;
          left: 28px;
          right: auto;
          bottom: auto;
          width: 70px;
          height: 40px;
          z-index: 1;
        }
      }
    }
  }
}
