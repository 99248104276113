//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
:root {
  --fancybox-bg: rgba(0,0,0,.8);
  --swiper-theme-color: $primary;
  --swiper-navigation-size: 24px;
  --swiper-pagination-bullet-width: 20px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bullet-inactive-color: #c2c2c2;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --gap: 96px;
  --gridgap: #{$grid-gutter-width * .5};
  @include media-breakpoint-down(xl) {
    --gap: 64px;
  }
  @include media-breakpoint-down(lg) {
    --gridgap: #{$grid-gutter-width-lg * .5};
    --gap: 56px;
  }
  @include media-breakpoint-down(sm) {
    --gap: 48px;
  }
  @include media-breakpoint-down(xs) {
    --gridgap: #{$grid-gutter-width-xs * .5};
    --gap: 16px;
  }
}

.frame-space-after-extra-small { padding-bottom: 25px !important; }
.frame-space-after-small { padding-bottom: 55px !important; }
.frame-space-after-medium { padding-bottom: 95px !important; }
.frame-space-after-large { padding-bottom: 200px !important; }
.frame-space-after-extra-large { padding-bottom: 350px !important; }

@include media-breakpoint-down(lg) {
  .frame-space-after-extra-small { padding-bottom: 10px !important; }
  .frame-space-after-small { padding-bottom: 20px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 80px !important; }
  .frame-space-after-extra-large { padding-bottom: 115px !important; }
}

@include media-breakpoint-down(sm) {
  .frame-space-after-large { padding-bottom: 80px !important; }
  .frame-space-after-extra-large { padding-bottom: 115px !important; }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
}
body {
  max-width: 100vw;
  transition: opacity .35s ease,margin-top .35s ease;
  @include media-breakpoint-down(md) {
    /*padding-top: 93px;*/
  }
  &.unmoved {
    padding-top: 0;
  }
  &.modal-open {
    padding-right: 0 !important;
    .main-header {
      padding-right: 0 !important;
    }
  }
  &.overflow-hidden {
    height: 100vh !important;
    height: calc(var(--vh, 1vh) * 100) !important;
    /*margin-top: calc(var(--meta));*/
    .main-header {
      /*margin-top: calc(var(--meta));*/
    }
  }
}

.close {
  opacity: 1;
  text-shadow: none;
}

.material-icon {
  display: inline-block;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  color: inherit;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
}

%resize-down-sm {
  @include media-breakpoint-down(sm) {
    font-size: $btn-font-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    line-height: $btn-line-height-sm;
  }
}
%input-resize-down-sm {
  @include media-breakpoint-down(sm) {
    font-size: $input-font-size-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    line-height: $input-line-height-sm;
    height: $input-height-sm;
  }
}

.link-arrow {
  &:after {
    display: inline-block;
    content: "north_east";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    color: inherit;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    padding-left: .4rem;
    vertical-align: -4px;
  }
  &.stretched-link {
    &:after {
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      position: static;
    }
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: rgba(0,0,0,0);
    }
  }

}

.header-content {
  position: relative;
  z-index: 2;
}

// content wrapper
.content-wrapper {
  max-width: $max-width-content-wide;
  padding: 0 $grid-gutter-width/2;
  margin: 0 auto;
  width: 100%;
}

.navigation-wrap {
  margin: 0 auto;
  width: 100%;
}

.floating-focus{
  border:0 solid currentColor;
  position:absolute;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  opacity:0;
  will-change:top, left, width, height;
  box-sizing:content-box;
  pointer-events:none;
  overflow:hidden;
  z-index:9999999999;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.56);
}
.floating-focus.moving{
  transition-property:opacity, left, top, width, height, border-width, border-radius;
  transition-duration:.2s, .1s, .1s, .1s, .1s, .1s, .1s;
  transition-timing-function:linear, ease, ease, ease, ease, ease, ease
}
.floating-focus.enabled.visible{
  opacity:1
}
.floating-focus::after{
  content:'';
  background:transparent;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:0;
  transition:opacity .2s linear;
}
.floating-focus.helper::after{
  transition-duration:.1s;
  opacity:.3
}
.floating-focused{
  outline-style:none !important
}
.floating-focused::-moz-focus-inner{
  border:0 !important
}

*:focus {
  outline: none;
}

.floating-focus-enabled :focus, .floating-focus-enabled .focus {
  outline: $black solid 1px;
  outline-offset: 4px;
}

.floating-focus-enabled [class~="btn-outline-primary"]:focus,
.floating-focus-enabled [class~="btn-outline-secondary"]:focus {
  outline-color: $white;
  outline-offset: 4px;
}

.fa-15x {
  font-size: 1.5rem;
}
.w-0 {
  width: 0;
}
.w-24 {
  width: 24rem;
}
.transition-all {
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .2s ease;
  &.fast {
    transition: width 0s ease;
  }

}

.text-bold {
  font-weight: 500;
}
.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-1031 {
  z-index: 1031;
}

.dropdown-toggle  {
  display: flex;
  align-items:center;
  &:after {
    transition: all .2s ease;
    border-width: .35em;
    margin-right: .25rem;
  }
  &[aria-expanded=true] {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.vh-100 {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.vh-200 {
  height: 200vh !important;
  height: calc(var(--vh, 1vh) * 200) !important;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.underlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.svg-icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  fill: currentColor;

  &.primary {
    color: $primary;
    --color1: #D70814;
  }
  &.secondary {
    color: $secondary;
    --color1: #9B9B9B;
  }
  &.black {
    color: $black;
    --color1: #000;
  }
  &.white {
    color: $white;
    --color1: #fff;
  }

  &.arrow-left {
    transform: rotate(180deg);
  }

  &.arrow-right {
    transform: rotate(0deg);
  }

  &.arrow-top {
    transform: rotate(-90deg);
  }
}

//.swiper-button-next::after, .swiper-button-prev::after {
//  font-family: "Material Icons";
//  font-weight: 400;
//  font-size: 1.5rem;
//  border-radius: 50%;
//  border-width: 3px;
//  border-style: solid;
//  border-color: var(--swiper-theme-color)
//
//}
//.swiper-button-next::after {
//  content: "chevron_right";
//}
//.swiper-button-prev::after {
//  content: "chevron_left"
//}
//.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
//  opacity: 1;
//  color: $dark;
//  &:after {
//    border-color: $dark;
//  }
//}
//.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
//  margin: 0 var(--swiper-pagination-bullet-horizontal-gap,4px);
//}
.object-cover {
  object-fit: cover;
}

.togsearch {
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 32px;
  right: 0;
  top: 0;
}

.p-40 {
  padding: 2.5rem;
}

.py-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pb-72 {
  padding-bottom: 4.5rem;
}
.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}

.bg-beige {
  background: $beige;
}
.bg-coral {
  background: $coral;
}
.bg-yellow1 {
  background: $yellow;
}
.bg-yellow2 {
  background: $darkyellow;
}
.bg-darkgreen {
  background: $darkgreen;
}
.bg-greyblue1 {
  background: $cyan;
}
.bg-greyblue2 {
  background: $blue;
}

.fakeScroll__track {
  right:0;
  width: 12px;
  z-index: 1;
  .fakeScroll__bar {
    width: 4px;
    background: rgba(0,0,0,1);
  }
}

.move-right {
  margin-right: -.25rem
}
.move-left {
  margin-left: -.25rem
}
.move-top {
  margin-top: -.25rem;
}
%navhover {
  .active,
  &:hover {
    color: $link-hover-color;
  }
}
.swiper-pagination-bullet {
  border-radius: 0;
  transition: $transition-base;
}
.swiper-pagination-bullet-active {
  width: 70px;
  @include media-breakpoint-down(sm) {
    width: var(--swiper-pagination-bullet-width);
  }
}

.swiper-container {
  overflow: hidden;
}

.ce-bodytext p:last-child {
  margin-bottom: 0;
}

.main-container > .animate > .frame,
.main-container > .frame {
  &.frame-background {
    background: $light;
    padding-top: 1.5rem;
    padding-bottom: 11rem;
    @include media-breakpoint-down(md) {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }
    clip-path: polygon(0 0, 100% 0, 100% 100%, var(--gap) calc(100% - (var(--gap) * 2)), 0 calc(100% - var(--gap) * 7.6));
  }
  &.frame-w-80 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.frame-w-60 {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
.footer-mask {
    &:after {
      position: absolute;
      z-index: -1;
      display: block;
      width: 100%;
      left: 0;
      top: auto;
      height: 100vh;
      background: $white;
      content: "";
      clip-path: polygon(0 0, 100% 0, 100% 100%,0 calc(100% - var(--gap) * 2));
      bottom: calc(var(--gap) * -2)
    }
}
.nomask + .footer-mask {
  display: none;
}

.text-column {
  margin-bottom: 1rem;
  column-count: 2;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.table-border {
  &-left {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      top: 0;
      left: 0;
      width: 149px;
      height: 75px;
    }
  }
  &-right {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      border-bottom: 2px solid $black;
      border-right: 2px solid $black;
      bottom: 0;
      right: 0;
      width: 149px;
      height: 75px;
    }
  }
}

.gray-text {
  color: $gray-600;
  font-weight: 700;
}

.table {
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 10px;
  }
  caption {
    padding-top: 1.875rem;
    padding-bottom: 0;
    color: $black;
    font-weight: 700;
    caption-side: bottom;
  }
  th,td {
    border-top: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid $gray-600;
  }
  tr {
    &:last-child {
      th,td {
        border-bottom: none;
      }
    }
  }
  &.table-border {
    th,td {
      border-right: none;
      padding-top: 25px;
      padding-bottom: 18px;
      padding-left: 25px;
      padding-right: 25px;
      position: relative;
      &:after {
        content: '';
        height: 50%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 25%;
        background-color: $gray-600;
      }
      &:last-child {
          &:after {
            content: none;
          }
      }
    }
  }
}

.bg-placeholder {
  background: $darkgreen;
  min-height: 389px;
  @include media-breakpoint-down(md) {
    min-height: 164px;
  }
  position: relative;
  header {
    position:  relative;
    z-index: 1;
  }
  &:after {
    position: absolute;
    content: "";
    width: 82%;
    height: 100%;
    right: 0;
    background: $green;
    clip-path: polygon(0 86px,100% 0,100% 100%,48px calc(100% - 28px));
    @include media-breakpoint-down(md) {
      width: 45%;
      clip-path: polygon(0 43px,100% 0,100% 100%,32px calc(100% - 16px));
    }
    z-index: 0;
  }
}

.ce-bodytext {
  .btn {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 1px;
}

.link-arrow-before,
.link-email,
.link-map,
.link-phone {
  &:before {
    vertical-align: middle;
    transform: translateY(-4px);
    font-family: "Material Icons";
    content: 'phone';
    margin-right: .75rem;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
  }
}
.link-arrow-before {
  &:before {
    content:"east"
  }
}
.link-email {
  &:before {
    content:"mail_outline"
  }
}
.link-map {
  &:before {
    font-family: 'Material Icons Outlined';
    content:"map"
  }
}

.click-item {
  @extend .font-size-ui;
  position: relative;
  padding: .25rem;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
  &.selected {
    color: $primary;
  }
  + .click-item {
    &:before {
      display: inline-block;
      content: "/";
      padding-bottom: 1px;
      margin-left: -.25rem;
      margin-right: .25rem;
    }
  }
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.w-sizer {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  + .wallsio-iframe {
    height: 0 !important;
  }
}
.wall {
  margin: 0 -21px !important;
}

.alternative-content {
  position: relative;
  overflow: hidden;
  .overlay {
    background: rgba($white,.8);
  }
  img {
    filter: blur(10px);
  }
}

.title-hr {
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-left: .5rem;
  }

  &.text-white:after {
    background-color: $white !important;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 2.5rem;
    left: calc(100% - 74px);
    right: auto;
    height: 2px;
    width: 212px;
    background: $black;

    @include media-breakpoint-down(sm) {
      left: 0;
      width: 2px;
      height: calc(100% + 27px);
      bottom: 0;
      top: auto;
    }
  }

  &.right:after {
    @include media-breakpoint-up(md) {
      left: calc(100% - 74px);
      right: auto;
    }
  }

  &.left:after {
    @include media-breakpoint-up(md) {
      left: auto;
      right: calc(100% - 74px);
    }
  }

}

.custom-nav {

  .swiper-button-next, .swiper-button-prev {
    position: initial;
    width: 55px;
    height: 66px;
    background-color: $white;
    &:after {
      display: none;
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  &.nav-secondary {
    .swiper-button-next, .swiper-button-prev {
      background-color: $white;
      border: 2px solid $secondary;

      .svg-icon {
        width: 55px;
        height: 66px;
        color: $secondary;
        --color1: #9B9B9B;
        transition: $transition-base;
      }

      &:hover {
        border: 2px solid $black;
        .svg-icon {
          color: $black;
          --color1: #000;
        }
      }

      .custom-nav-arrow {
        width: 32px;
        height: 34px;
        margin: auto;
      }
    }
  }

}

.serv-border {
  &-left {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      top: 0;
      left: 0;
      width: 149px;
      height: 75px;
    }
  }
  &-right {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      border-bottom: 2px solid $black;
      border-right: 2px solid $black;
      bottom: 0;
      right: 0;
      width: 149px;
      height: 75px;
    }
  }

  @include media-breakpoint-down(sm) {
    &-right:after, &-left:before {
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      border-right: none;
      border-bottom: none;
      top: 42px;
      left: 28px;
      right: auto;
      bottom: auto;
      width: 70px;
      height: 40px;
    }
  }
}
.glossary,
.frame-type-text,
.tx-cs2-textElement {

  .text-column {
    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }
}



.left-offset {
  padding-left: 124px;
  @include media-breakpoint-down(sm) {
    padding-left: 64px !important;
    padding-top: 2rem;
  }

  .lead-border {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    bottom: auto;
    top: calc(100% - 14px);
    right: calc(100% + 20px);
    width: 94px;
    height: 100%;
    color: $primary;
    font-weight: $font-weight-normal;
    font-size: .875rem;
    border-top: 2px solid $black;

    @include media-breakpoint-down(sm) {
      bottom: auto;
      top: -40px;
      right: 0;
      left: 3px;
      width: auto;
      border-top: none;

      &:before {
        content: '';
        position: absolute;
        bottom: auto;
        top: calc(100% + 1rem);
        right: calc(100% + 14px);
        width: 50px;
        height: 2px;
        background: $black;
      }
    }
  }
}

.no-before {
  &:before {
    display: none !important;
    content: none !important;
  }
}

.no-after {
  &:after {
    display: none !important;
    content: none !important;
  }
}

.h-max {
  height: max-content !important;
}
.h-min {
  height: min-content !important;
}

.fancybox__container {
  z-index: 4010;
}

.list-style-position-inside {
  list-style-position: inside;
  @include media-breakpoint-down(sm) {
    list-style-position: initial;
  }
}

a {
  &.dpnglossary.link {
    color: $black;
    border-bottom: 1px dashed;
    text-decoration: none;
    &:hover {
      &:after {
        background: #fff;
        border-radius: 0;
        border: 1px solid #ee1c45;
        top: 25px;
        bottom: initial;
        color: #ee1c45;
        content: attr(title);
        left: 2em;
        padding: 4px;
        position: absolute;
        width: auto;
        white-space: nowrap;
        z-index: 98;
      }
      &:before {
        content: none;
      }
    }
  }
}

.word-break-break-word {
  word-break: break-word;
}

.border-width-2 {
  border-width: 2px!important;
}
