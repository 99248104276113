.persons-item {
  .persons-image {
    max-width: calc(155px + 1.5rem);
    min-width: calc(155px + 1.5rem);
    padding-right: 1.5rem;
    @include media-breakpoint-down(md) {
      min-width: calc(114px + 1.25rem);
      padding-right: 1.25rem;
    }
  }
  .ratio-persons {
    &:after {
      padding-bottom: 129.66%;
    }
  }
}
