.btn {
  outline: none;
  box-shadow: none;
  font-weight: $font-weight-bolder;
  font-size: $input-btn-font-size;
  min-width: 230px;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  .span {
    width: min-content;
  }

  &:before {
    content: '';
    display: block;
    position: initial;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    background-repeat: no-repeat !important;
    margin-left: 2rem;
    background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
  }

  &.slide-effect {
    background: none !important;
    z-index: 2;
    position: relative;
    &:hover {
      background: none !important;
      &:after {
        width: calc(100% + 1px);
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: calc(100% + 1px);
      left: -1px;
      top: -1px;
      background: transparent;
      -webkit-transition: width ease-in-out 0.5s;
      -moz-transition: width ease-in-out 0.5s;
      transition: width ease-in-out 0.5s;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 8px 12px;
    font-size: $input-btn-font-size-md;
    min-width: 180px;
    &:before {
      margin-left: 20px;
    }
  }

  &.no-icon {
    min-width: fit-content;
    width: fit-content;

    &:before {
      display: none;
    }
  }
  .svg-icon {
    width: 1.75rem;
    height: 1.75rem;
  }

  &.w-min {
    min-width: fit-content;
    width: fit-content;
  }

  &.w-max {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    &.w-md-min {
      min-width: fit-content;
      width: fit-content;
    }
    &.w-md-max {
      width: 100%;
    }
    &.w-md-normal {
      min-width: 230px;
      width: fit-content;
    }
  }

  @extend %resize-down-sm;
  &.btn-nostyle {
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
  &.btn-primary {
    background-color: $white;
    color: $black;
    border-color: $black;

    &:before {
      background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
    }

    &:hover, &.active, &:active, &:focus {
      background-color: $white !important;
      border-color: $black !important;
      color: $white !important;
      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-white.svg");
      }
    }

    z-index: 2;
    position: relative;
    &:hover, &.active, &:active, &:focus {
      &:after {
        width: calc(100% + 1px);
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: calc(100% + 1px);
      left: -1px;
      top: -1px;
      background: $black !important;
      -webkit-transition: width ease-in-out 0.5s;
      -moz-transition: width ease-in-out 0.5s;
      transition: width ease-in-out 0.5s;
    }

    &.light {
      color: $white;
      border-color: $white;

      &:after {
        background: $white !important;
      }

      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-white.svg");
      }
      &:hover, .active, &:active,  &:focus {
        background-color: $white;
        border-color: $white;
        color: $black !important;
        &:before {
          background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
        }
      }
    }
  }
  &.btn-outline-primary {
    background-color: transparent;
    color: $black;
    border-color: $black;

    &:before {
      background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
    }

    &:hover, .active, &:active, &:focus {
      background-color: $black !important;
      border-color: $black !important;
      color: $white !important;
      box-shadow: none !important;
      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-white.svg");
      }
    }

    background: none !important;
    z-index: 2;
    position: relative;
    &:hover {
      background: none !important;
      &:after {
        width: calc(100% + 1px);
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: calc(100% + 1px);
      left: -1px;
      top: -1px;
      background: $black !important;
      -webkit-transition: width ease-in-out 0.5s;
      -moz-transition: width ease-in-out 0.5s;
      transition: width ease-in-out 0.5s;
    }

    &.light {
      color: $white;
      border-color: $white;

      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-white.svg");
      }
      &:hover, .active, &:active,  &:focus {
        background-color: $white;
        border-color: $white;
        color: $black !important;

        &:before {
          background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
        }
      }

      &:after {
        background: $white !important;
      }
    }

    &.btn-back {
      flex-direction: row;
      &:before {
        margin-left: 0;
        margin-right: 2rem;
        transform: rotate(180deg);
      }
    }
  }
  &.btn-secondary {
    border-color: $white;
    color: $black;
    background-color: $white;

    &:before {
      background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-black.svg");
    }

    &:hover, .active, &:active,  &:focus {
      background-color: $white;
      border-color: $white;
      color: $primary;

      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-primary.svg");
      }
    }

    &.slide-effect:after {
      background: $white !important;
    }

    &.disabled,
    &:disabled {
      background: $white;
      color: $gray-700;
      border-color: $gray-100 !important;
    }
  }
  &.btn-outline-secondary {
    border-color:$black;
    color: $black;
    background-color: $white;

    &:before {
      background: none;
    }

    &:hover, .active, &:active,  &:focus {
      background-color: $black;
      border-color: $black;
      color: $white;

      &:before {
        background: url("/typo3conf/ext/cs2_prototypes/src/assets/images/serv-arrow-white.svg");
        transform: rotate(90deg);
      }
    }

    &.slide-effect:after {
      background: $black !important;
    }

    &.disabled,
    &:disabled {
      color: $white;
      border-color: $white;
      background: $primary;
    }
  }
  &.btn-link {
    align-self: flex-start;
    padding: 0;
    display: inline-flex;
    align-items: center;
    border:none;
    color: $black;

    &.arrow {
      &:before {
        @extend .material-icon;
        content: "east";
        padding-right: .875rem;
      }
    }
    &:hover, &:focus, &:active {
      color: $primary;
      box-shadow: none !important;
      text-decoration: none !important;
    }
  }
}
