.tx-cs2-historyElement {

  .history-content {
    position: relative;
    padding: 1.5rem;
    text-align: left;

    .nav-wrapper {
      justify-content: flex-start;
    }

    @include media-breakpoint-down(sm) {
      text-align: right;
      padding: 1.5rem .8rem;

      .nav-wrapper {
        justify-content: flex-end;
      }

      &.align-left {
        text-align: left;
        .title-hr:after {
          right: auto;
          left: 0;
        }
        .nav-wrapper {
          justify-content: flex-start;
        }
      }
    }

    @include media-breakpoint-up(md) {
      background-color: transparent;

      h2:after {
        background-color: $black !important;
      }
    }

    .content-position {
      padding-right: 0;
    }
  }

  .title-hr {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
      position: initial;
    }

    &:after {
      right: calc(100% - 62px);
      width: 212px;

      @include media-breakpoint-down(sm) {
        right: 0;
        left: auto;
        height: calc(100% + -108px);
        width: 2px;
        bottom: 108px;
        top: auto;
      }
    }

  }

  .historySwiper {

    @include media-breakpoint-down(sm) {
      padding-right: 50px;

      .swiper-slide {
        transform: scale(.95) !important;
        transition: transform .3s ease-in-out;

        &.swiper-slide-active {
          transform: scale(1) !important;
        }
      }
    }
    .historySwiper-nav {
      display: none;
    }
    .nav-position {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 80px;
      height: auto;

      @include media-breakpoint-up(md) {
        width: 120px;
        height: 66px;
        margin-bottom: 1.5rem;
      }

      .historySwiper-nav {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 120px;
        height: inherit;
        @include media-breakpoint-down(sm) {
          width: 80px;
          height: 48px;

          .swiper-button-next, .swiper-button-prev {
            width: 38px;
            height: 48px;

            border: 2px solid $secondary;

            img {
              height: 22px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  &.swiper-container {
    @include media-breakpoint-up(xl) {
      padding: 0 20px;
    }
  }

  .historyPaginationSwiper {
    height: 120px;

    .swiper-slide {
      cursor: pointer;

      transition: filter .2s ease-in-out;
      -webkit-backdrop-filter: blur(0);
      filter: brightness(100%);

      &.swiper-slide-thumb-active {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        filter: brightness(45%);
      }
    }

    .videoPaginationSwiper-moreButton {
      position: absolute;
      left: 0;
      top: calc(50% - 22px);
      display: none;
    }

    .swiper-slide-thumb-active + .videoPaginationSwiper-moreButton {
      display: block;
    }
  }

  .historyPaginationList {
    height: 120px;
    overflow: initial;
    .swiper-slide {
      cursor: pointer;
    }

    .videoPaginationList-moreButton {
      position: absolute;
      left: 0;
      top: calc(50% - 22px);
      &.right {
        right: -8.33333%;
        left: unset;
      }
    }
  }

  .history-separator {
    padding-bottom: 200px;
    &:after {
      content: '';
      position: absolute;
      height: 180px;
      width: 2px;
      bottom: 20px;
      left: 50%;
      right: auto;
      background: $black;
    }
  }
}
