.tx-cs2-pageheader {
  .clip {
    clip-path: polygon(0 26%, 100% 0, 100% 100%, 11% 100%);
    width: calc(100% + var(--gap));
    max-height: 720px;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
}
