.ce-image {
  @include media-breakpoint-down(sm) {
    figure {
      margin: 0;
    }
    .image-gallery {
      .row > * {

      }
    }
  }
}
